import * as Sentry from "@sentry/vue";
import packageJson from '../package.json';
import useAuthStore from "~/stores/auth/useAuthStore";

async function lazyLoadSentryIntegrations() {
  const { replayIntegration } = await import("@sentry/vue");
  Sentry.addIntegration(replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
    maskAllInputs: false,
  }));
}

function getSentryIntegrations() {
  const router = useRouter();
  const browserTracing = Sentry.browserTracingIntegration({ router });

  return [
    browserTracing,
    Sentry.extraErrorDataIntegration(),
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
    Sentry.httpClientIntegration(),
  ];
}

function getUser() {
  return useAuthStore().me?.login
}

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    console.log('isProduction', nuxtApp.$config.public.ENVIRONMENT)
    if (nuxtApp.$config.public.ENVIRONMENT !== 'production') return;

    const vueApp = nuxtApp.vueApp;

    Sentry.init({
      app: vueApp,
      enabled: nuxtApp.$config.public.ENVIRONMENT === 'production',
      maxValueLength: 10000,
      environment: nuxtApp.$config.public.ENVIRONMENT,
      release: packageJson.version,
      dsn: "https://08440f6c879a159084d6c142a0acdef7@o4508490517250048.ingest.de.sentry.io/4508490522230864",
      // integrations: getSentryIntegrations(),

      tracesSampleRate: 1,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
    });

    Sentry.setUser({ email: getUser() })

    // Lazy-load the replay integration to reduce bundle size
    lazyLoadSentryIntegrations();
  }
});
