<template lang="pug">
#page-error(:class="!withLayout && 'page-view'")
  .content
    ui-logo.logo
    h2 Ошибка обращения к сервису, пожалуйста, обновите страницу
    h4
      | Скорее всего, мы обновили версию системы, что вызвало подмену некоторых файлов.
      |
      b Пожалуйста, обновите страницу
    h4 Если же дело не в этом, то уверены, наша команда уже устраняет неисправность. Приносим извинения за доставленные неудобства
    .actions
      ui-button(size="large" type="secondary" :icon-left="UiIconNames.Icon_Refresh" @click="refresh") Обновить страницу
      ui-button(v-if="showMainButton" size="large" type="secondary" @click="goToMain") На главную
  img.pic(src="/pics/background/server-room.png")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";

import UiLogo from "~/components/ui/logo/UiLogo.vue";
import UiButton from "~/components/ui/button/UiButton.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import { Tabs } from "@/router/tabs";

export default defineComponent({
  name: "PageError",
  components: {
    UiLogo,
    UiButton,
  },
  props: {
    withLayout: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    const route = useRoute()
    const router = useRouter()
    const showMainButton = computed(() => route.name !== Tabs.Search && route.meta?.requiresAuth)

    function refresh() {
      try {
        window.location.reload();
      } catch {
        console.log('error')
      }
    }

    function goToMain() {
      router.push({ name: Tabs.Search })
    }

    return {
      showMainButton,
      refresh,
      goToMain,
      UiIconNames,
    }
  }
});
</script>

<style scoped lang="scss">
#page-error {
  display: grid;
  grid-template-columns: auto 400px;
  gap: 80px;

  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  align-items: center;

  h2 {
    margin: 16px 0;
  }

  h4 {
    margin: 0;
    font-weight: normal;
  }

  &:not(.page-view) {
    padding: 0 24px;
  }
}

.page-view {
  background-color: #f8fafc;
  padding: 160px 160px;
}

.content {
  display: flex;
  flex-flow: column;
  gap: 40px;
  align-items: flex-start;
}

.actions {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
}

.pic {
  width: inherit;
}

@media (max-width: 1340px) {
  #page-error {
    padding: 120px 80px;
  }
}

@media (max-width: 1040px) {
  #page-error {
    grid-template-columns: auto 320px;
    padding: 80px 40px;
    gap: 40px;
  }
}

@media (max-width: 820px) {
  #page-error {
    grid-template-columns: auto 240px;
    padding: 40px 40px;
    gap: 24px;

    .content {
      gap: 24px;
    }

    h2 {
      font-size: 28px;
      margin: 0;
    }

    h4 {
      font-size: 16px;
    }
  }
}

@media (max-width: 560px) {
  #page-error {
    display: flex;
    flex-flow: column;

    .content {
      gap: 16px;
    }

    h2 {
      font-size: 20px;
      margin: 0;
    }

    h4 {
      font-size: 14px;
    }
  }
}
</style>
