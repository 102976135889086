<template>
  <svg viewBox="0 0 990 560" xmlns="http://www.w3.org/2000/svg">
    <g>
      <RegionPath
        @show-tooltip="$emit('showTooltip', $event)"
        @hide-tooltip="$emit('hideTooltip')"
        @mousemove="$emit('mousemove', $event)"
        v-for="(item, key) in resultStat"
        :key="key"
        :maxKey="maxKey"
        :index="key"
        :region="item"
        :chartStat="chartStat"
      />
    </g>
  </svg>
</template>

<script lang="ts">
// @ts-ignore
import regions from "@/components/pages/analytics/statistics/mapChart/json/russia.json";
import {computed, defineComponent, toRefs} from "vue";

import RegionPath from "./RegionPath.vue";

const KeyField = 'RegionCode';

export default defineComponent({
  components: {
    RegionPath,
  },
  emits: [
    "showTooltip",
    "hideTooltip",
    "mousemove",
  ],
  props: {
    chartStat: {
      type: String,
      default: "lotSummary",
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {

    const { data, chartStat } = toRefs(props);

    const maxKey = computed(() => data.value?.reduce((res: any, item: any) => {
      return Math.max(res, item[chartStat.value]);
    }, 0));

    const resultStat = computed(() => regions.map((r: any) => {
      const d = data.value.find((rd: any) => r[KeyField] === rd[KeyField]) || {};
      return {...r, ...d};
    }));

    return {
      resultStat,
      maxKey,
    };
  },
});
</script>

<style scoped lang="scss">
svg {
  width: 100%;
  height: inherit;

  .state {
    fill: #f2f2f2;
    opacity: 0.75;
    stroke-width: 1;
    stroke: var(--main-color-blue2);
    -webkit-transition: stroke 0.5s, stroke-width 0.5s;
    -o-transition: stroke 0.5s, stroke-width 0.5s;
    transition: stroke 0.5s, stroke-width 0.5s;

    &.city {
      stroke: #000 !important;
      opacity: 1 !important;
    }
  }

  .state:hover,
  .state.active {
    cursor: pointer;
    stroke-width: 1;
    stroke: #000;
  }
}

p {
  margin: 0 0 10px;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.75;
}
</style>
