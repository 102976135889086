<template lang="pug">
.flex-column.g16
  ui-loader(v-if="isFetching")
  .flex-column.g16(v-else-if="!showErrorWhileFetch")
    .flex-column(v-if="data.length")
      .flex-row.tag(v-for="(tag, index) of data" :key="tag.id + index")
        .flex-row.plain
          .color(:style="`background-color: ${tag.color}`")
          .label {{ tag.title }}
        .actions
          mini-loader.loader(v-if="modifiedId === tag.id")
          icon-button(
            v-else
            :size="16"
            :icon="UiIconNames.Icon_Refresh"
            tooltip-title="Восстановить тег"
            @click="restoreTag(tag)"
          )
    .empty-tags(v-else-if="!showErrorWhileFetch")
      span(v-if="page === 1") Список удаленных тегов пуст
      .page-not-valid(v-else)
        span Указанная страница пустая, перезагрузите таблицу
        ui-button(type="secondary" :icon-left="UiIconNames.Icon_Refresh" @click="getDeletedTags(1)") Перезагрузить таблицу
  span.error-message(v-if="showErrorWhileFetch") Произошла ошибка при получении списка удаленных тегов
  ui-pagination(
    v-if="totalPages > 1"
    :count-results="data.length"
    :total-results="totalResults"
    :current-page="page"
    :total="totalPages"
    @update:current-page="getDeletedTags($event)"
  )
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { storeToRefs } from "pinia";
import { useApi } from "~/use/api/useApi";
import { useSuperAccount } from "~/use/superAccount/useSuperAccount";

import useSearchStore from "~/stores/search/useSearchStore";
import UiLoader from "~/components/ui/loader/UiLoader.vue";
import MiniLoader from "~/components/ui/loader/MiniLoader.vue";
import IconButton from "~/components/ui/button/IconButton.vue";
import UiPagination from "~/components/ui/pagination/UiPagination.vue";
import UiButton from "~/components/ui/button/UiButton.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import type { TenderTagI } from "~/stores/search/LotItemInterface";

export default defineComponent({
  name: "DeletedTagsManagement",
  components: {
    UiButton,
    UiPagination,
    IconButton,
    MiniLoader,
    UiLoader,
  },
  setup() {

    const modifiedId = ref();
    const isFetching = ref(false);          // loader при запросе списка тегов

    const showErrorWhileFetch = ref(false);   // ошибка при запросе списка тегов
    const showErrorWhileSave = ref(false);    // ошибка при сохранении тега

    const searchStore = useSearchStore();
    const { tags: actualTags } = storeToRefs(searchStore);

    const { getDotQueryActual } = useSuperAccount();

    const data = ref<TenderTagI[]>([]);
    const page = ref<number>(1);

    const totalResults = ref<number>(0);
    const totalPages = ref<number>(1);

    /** запрос удаленных тегов (для тм менеджера) */
    function getDeletedTags(p = 1) {
      page.value = p
      isFetching.value = true
      showErrorWhileFetch.value = false

      useApi().tags.fetchTenderTags(getDotQueryActual(true) + `&page=${p}`)
        .then((hydraData) => {
          totalResults.value = Number(hydraData['totalItems'])
          totalPages.value = Math.ceil(hydraData['totalItems'] === 0 ? 1 : (hydraData['totalItems'] / 30))
          data.value = hydraData['member']
        })
        .catch(() => showErrorWhileFetch.value = true)
        .finally(() => isFetching.value = false)
    }

    /** восстановление тегов */
    function restoreTag(tag: TenderTagI) {
      modifiedId.value = tag.id
      showErrorWhileSave.value = false

      useApi().tags.restoreTenderTag(tag.id)
        .then(() => {
          const index = data.value.findIndex(e => e.id === tag.id);
          if (index !== -1) data.value.splice(index, 1);
          totalResults.value = totalResults.value - 1
          if (data.value.length === 0 && page.value > 1) getDeletedTags(page.value - 1)

          actualTags.value.push(tag)
        })
        .catch(() => showErrorWhileSave.value = true)
        .finally(() => modifiedId.value = null)
    }

    function pushDeletedTag(tag: TenderTagI) {
      if (totalResults.value > 0 && totalResults.value % 30 === 0) totalPages.value++
      if (totalResults.value < 30) data.value.push(tag)
      totalResults.value = totalResults.value + 1
    }

    getDeletedTags()

    return {
      data,
      page,
      totalResults,
      totalPages,
      modifiedId,
      isFetching,
      showErrorWhileFetch,
      showErrorWhileSave,
      restoreTag,
      pushDeletedTag, // для внешней компоненты
      getDeletedTags,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import 'assets/styles/mixin/fonts';

.flex-row {
  display: flex;
  flex-flow: row;
  gap: 8px;
}

.flex-column {
  display: flex;
  flex-flow: column;

  &.g16 {
    gap: 16px;
  }
}

.error-message {
  @include label-13-18;
  color: var(--main-red-color);
}

.empty-tags {
  font-size: 14px;
  line-height: 20px;
}

.tag {
  padding: 8px 12px;
  align-items: start;

  &:hover {
    background-color: #fbfbfb;
  }
}

.plain {
  font-size: 14px;
}

.color {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  margin: 0 6px;
}

.actions {
  display: flex;
  margin-left: auto;
}

.loader {
  margin: 0 6px;
}

.page-not-valid {
  display: flex;
  flex-flow: column;
  gap: 16px;
  padding: 24px;
  text-align: center;
  align-items: center;
}
</style>
