<template lang="pug">
.promo-dashboard
  .promo-details
    .promo-label
      .label Название акции
      .description Для внутреннего использования
    .settings
      form-input(v-model="promo.serviceTitle" label="Название")
  .promo-details
    .promo-label
      .label Главный экран
      .description Детали акции для отображения на главном экране
    .settings
      form-textarea(v-model="promo.landingPromoTitle" label="Заголовок" :min-height="36")
      form-textarea(v-model="promo.landingPromoAgreement" label="Сноска")
  .promo-details
    .promo-label
      .label Информационная строка
      .description Детали акции для отображения над тарифами
    .settings
      form-textarea(v-model="promo.promoTitle" label="Заголовок" :min-height="36")
      form-textarea(v-model="promo.promoDescription" label="Описание")
      form-textarea(v-model="promo.promoAgreement" label="Подробности")
  .promo-details
    .promo-label
      .label Видимость акции
      .description Прочие настройки
    .settings
      radio-group.row(v-model="newUsersOnlyComp" :options="options" id="promo-user-mode")
  .promo-details
    .promo-label
      .label Цены
      .description Прочие настройки
    .manual-prices
      .tariff(v-for="t of defaultPrices" :key="`tariff-row-${t.tariffId}`")
        .tariff-label Тариф "{{ t.tariffTitle }}"
        .tariff-setter
          manual-price-setter(
            v-for="p of periods"
            :key="`setter-${t.tariffId}-${p}`"
            v-model="promo.rules[`${t.tariffId}-${p}`]"
            :period="p"
            :price="getPrice(t.cost, p)"
            :base-cost="t.cost"
            v-model:selectedRules="selectedRules"
            :rule-key="`${t.tariffId}-${p}`"
          )
      .tariff
        span
        .rules-setter
          .all-setter
            ui-checkbox(v-model="selectAllGetter" id="selectAllPromoRules" label="Выбрать все" :indeterminate="isIndeterminate && !selectAllGetter")
            span.action(v-if="selectedRules.length" @click="showRulesSetter") + Указать скидку для выбранных элементов
          .rules(v-if="active && selectedRules.length")
            form-input(v-model="discount" label="Размер скидки в процентах (1-100)")
            ui-button.fit-height(type="secondary" @click="setRules") Добавить
            ui-button.fit-height(type="secondary" @click="cancel") Отменить
  .promo-details
    .promo-label
      .label Даты
    .flex-column
      .flex-row
        date-picker.picker(v-model="promo.dateStart" label="Дата начала акции")
        date-picker.picker(v-model="promo.dateFinish" label="Дата окончания акции")
  .promo-details
    .promo-label
      .label Сохранение данных
    .flex-column
      .promo-save
        radio-group(v-model="promo.periodType" :options="promoPeriodTypes")
        .flex-row
          ui-checkbox(v-model="promo.isActual" label="Опубликовать" id="public-the-promo")
          ui-button(@click="savePromo") Сохранить
      span.error-message(v-if="showErrorMessage") Проверьте, что поля заполнены корректно. Должен быть заполнен хотя бы один заголовок, а так же указана дата окончания акции.
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useVModel } from "@vueuse/core";
import { useApi } from "@/use/api/useApi";

import FormInput from "~/components/ui/form/input/FormInput.vue";
import FormTextarea from "~/components/ui/form/input/FormTextarea.vue";
import RadioGroup from "~/components/ui/radio/RadioGroup.vue";
import ManualPriceSetter from "~/components/pages/admin/promo/ManualPriceSetter.vue";
import UiButton from "~/components/ui/button/UiButton.vue";
import DatePicker from "~/components/ui/picker/DatePicker.vue";
import UiCheckbox from "~/components/ui/checkbox/UiCheckbox.vue";

import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";
import { type PromoI, usePromo } from "~/use/other/usePromo";

export default defineComponent({
  name: "PromoEditor",
  components: {
    FormInput,
    FormTextarea,
    RadioGroup,
    UiButton,
    UiCheckbox,
    DatePicker,
    ManualPriceSetter,
  },
  props: {
    modelValue: {
      type: Object as PropType<PromoI>,
      default: () => ({}),
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, context) {

    const promo = useVModel(props, 'modelValue', context.emit);
    const showErrorMessage = ref(false);

    const { showError } = useNotificationsStore();
    const { promoPeriodTypes } = usePromo();

    /** для поля newUsersOnly */
    const options = [
      { id: 1, title: 'Акция доступна только новым пользователям', },
      { id: 2, title: 'Акция доступна всем пользователям', },
    ]

    const newUsersOnlyComp = computed({
      get: () => promo.value?.newUsersOnly ? 1 : 2,
      set(value: number) {
        if (promo.value) promo.value.newUsersOnly = value === 1
      }
    })

    /** выбор нескольких тарифов для задания общего правила */
    const selectedRules = ref<string[]>([]);

    const active = ref(false)
    const discount = ref(0)

    function showRulesSetter() {
      if (selectedRules.value.length) active.value = true;
      else showError('Выберите нужные тарифы')
    }

    function setRules() {
      if (Number(discount.value) && !!selectedRules.value.length) {
        selectedRules.value.forEach(rule => {
          promo.value.rules[rule] = { value: discount.value, isRubles: false }
        })

        cancel()
      }
    }

    function cancel() {
      selectedRules.value = []
      active.value = false
    }

    const defaultPrices = [
      { tariffId: 1, cost: 1140, tariffTitle: 'Эконом' },
      { tariffId: 2, cost: 3500, tariffTitle: 'Я - Специалист' },
      { tariffId: 4, cost: 8700, tariffTitle: 'Максимум' },
    ]

    const periods = [1, 6, 12]

    const allIds = computed<string[]>(() => ['1-1', '1-6', '1-12', '2-1', '2-6', '2-12', '4-1', '4-6', '4-12'])

    const selectAllGetter = computed({
      get: () => allIds.value.every(id => selectedRules.value?.includes(id)),
      set(value: boolean) {
        selectedRules.value = value ? allIds.value : []
      }
    })

    const isIndeterminate = computed(() => allIds.value.some(id => selectedRules.value?.includes(id)))

    function getPrice(baseCost: number, period: number) {
      return period === 1 ? baseCost : Math.trunc((baseCost * (period === 6 ? 0.85 : 0.8) * period)/100)*100
    }

    function prepareRules(r: any) {
      let res = []
      Object.keys(r).forEach(key => {
        if (r[key]?.value) res.push({
          tariff: `/api/tariffs/${key.split('-')[0]}`,
          tariffDuration: Number(key.split('-')[1]),
          isRubles: r[key].isRubles,
          value: Number(r[key].value),
        })
      })
      return res;
    }

    /** сохранение изменений */
    function savePromo() {
      showErrorMessage.value = false

      if (!isPromoCorrect()) showErrorMessage.value = true
      else {

        const payload: any = {
          ...promo.value,
          rules: prepareRules(promo.value.rules),
          periodType: `/api/promo-period-types/${ promo.value.periodType }`,
          isActual: undefined,
          id: undefined,
          dateCreate: undefined,
        };

        (props.isCreate
          ? useApi().promo.postPromo(payload)
          : useApi().promo.patchPromo(promo.value?.id, payload))
            .then(() => {})
            .catch(() => showError(props.isCreate ? 'Не удалось создать акцию' : 'Не удалось обновить акцию'))
      }
    }

    function isPromoCorrect() {
      return promo.value?.dateFinish && (promo.value?.landingPromoTitle || promo.value?.promoTitle)
    }

    return {
      promo,
      promoPeriodTypes,
      periods,
      options,
      active,
      discount,
      showErrorMessage,
      selectedRules,
      selectAllGetter,
      isIndeterminate,
      showRulesSetter,
      setRules,
      getPrice,
      savePromo,
      cancel,
      defaultPrices,
      newUsersOnlyComp,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';

.error-message {
  @include error-message;
}

.promo-dashboard {
  display: flex;
  flex-flow: column;
  gap: 16px;
}

.promo-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;

  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid #e1e2e6;
  }

  .promo-label {
    display: flex;
    flex-flow: column;
    gap: 8px;

    .label {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
    }

    .description {
      font-size: 12px;
      line-height: 18px;
      color: var(--secondary-text-color);
    }
  }

  .settings {
    display: flex;
    flex-flow: column;
    gap: 16px;
  }

  .numeric-row {
    display: flex;
    flex-flow: row;
    gap: 12px;

    span {
      font-size: 11px;
      line-height: 17px;
    }
  }
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.all-setter {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 8px;
}

.manual-prices {
  display: flex;
  flex-flow: column;
  gap: 24px;
}

.tariff {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 16px;

  .tariff-label {
    font-size: 14px;
    line-height: 32px;
    font-weight: 600;
  }

  .tariff-setter {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }
}

.flex-row {
  display: flex;
  flex-flow: row;
  gap: 16px;
  align-items: center;
}

.picker {
  width: 200px;
}

.flex-column {
  display: flex;
  flex-flow: column;
  gap: 16px;
}

.promo-save {
  display: flex;
  flex-flow: row;
  gap: 16px;
  justify-content: space-between;
  align-items: end;
}

.action {
  color: var(--default-blue-color);
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
  font-size: 14px;
}

.rules-setter {
  display: flex;
  flex-flow: column;
  gap: 12px;
}

.rules {
  display: flex;
  flex-flow: row;
  gap: 8px;
  align-items: end;
}

.fit-height {
  height: fit-content;
}
</style>
