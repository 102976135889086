<template lang="pug">
promotion(v-if="showPromo" :promo="promo")

.flex-column
  .flex-column
    .settings-title.m-t-32(v-if="!hideLabels")
      slot(name="title") Тарифы
    the-tariff-switcher(@set-discount="setDiscount")
    .tariff-page__list-container
      tariff-column(
        title="Минимальный"
        description="Для тех, кто редко участвует в закупках"
        :tariff-id="1"
        :base-cost="1140"
        :discount="discount"
        :period="period"
        :include-options="[2, 3, 10, 11, 18, 19]"
        user-count="1 пользователь"
        description-hint="Минимальная цена за доступ к полной базе государственных и коммерческих тендеров"
        @select-tariff="selectTariff(23, 'Минимальный', period)"
      )
      tariff-column(
        hit
        title="Я - Специалист"
        description="Для специалистов по работе с тендерами"
        :tariff-id="2"
        :base-cost="3500"
        :discount="discount"
        :period="period"
        :include-options="[2, 3, 4, 5, 6, 10, 11, 12, 13, 14, 15, 18, 19, 20, 21]"
        user-count="3 пользователя"
        description-hint="Широчайшие возможности поиска закупок, повседневного контроля и управления своими заявками"
        @select-tariff="selectTariff(23, 'Я - Специалист', period)"
      )
      tariff-column(
        hit-label="Эксклюзив"
        title="Максимум"
        description="Для среднего и крупного бизнеса"
        :tariff-id="4"
        :base-cost="8700"
        :discount="discount"
        :period="period"
        :include-options="[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21]"
        user-count="10 пользователей"
        description-hint="Полные возможности системы для решения любых задач по поиску и управления тендерами"
        price-hint="Цена может быть увеличена в случае сложных или нестандартных требований к экспертному поиску"
        @select-tariff="selectTariff(23, 'Максимум', period)"
      )
  ui-button.register-button(v-if="!isAuth" @click="showDialog('registration')") Получить полный доступ на 7 дней бесплатно
  .flex-column.m-t-32
    .settings-title(v-if="!hideLabels") Дополнительные предложения
    .tariff-page__additional-offers
      settings-panel
        template(#header) ТендерМонитор “Конструктор”
        .additional-offer-content
          | Мы подберем для вас только необходимые опции без лишних функций,
          | однако пакетная скидка за тариф будет не доступна.
        template(#actions)
          ui-button(type="secondary" @click="selectOrder(23, 'ТендерМонитор - Конструктор')") Оставить заявку
      settings-panel
        template(#header) Услуга “Электронная подпись”
        .additional-offer-content
          .offer-paragraph
            .label Консультирование по вопросам оформления электронной подписи (государственные и коммерческие площадки)
          .offer-paragraph
            .label Защищенный носитель для хранения ЭП
            span.offer-cost._right 2 500 руб
          .offer-paragraph
            .label Бессрочная Лицензия на право использования СКЗИ "КриптоПро CSP" версии 5.0
            span.offer-cost._right от 3 000 руб
          .offer-paragraph
            .label Лицензия на право использования СКЗИ "КриптоПро CSP" версии 5.0 (годовая)
            span.offer-cost._right от 1 600 руб
          .offer-paragraph
            .label Аккредитация на государственных электронных площадках (регистрация в ЕИС) для 44-ФЗ
            span.offer-cost._right 7 200 руб
        template(#actions)
          ui-button(type="secondary" @click="selectOrder(5, 'Электронная подпись')") Оставить заявку
      settings-panel
        template(#header) Услуга “Аналитика по победителям”
        .additional-offer-content
          p
            | Регулярный мониторинг победителей закупок на определенную тематику.
            | Выгрузка названий и контактной информации победителей,
            | сведений о закупке, заказчике, начальной и предложенной цене.
          p
            | Позволяет предлагать Ваши товары и услуги тем,
            | кому они действительно будут нужны в ближайшее время.
          p.offer-cost от 8 400 руб/месяц
        template(#actions)
          ui-button(type="secondary" @click="selectOrder(4, 'Аналитика по победителям')") Оставить заявку
      settings-panel
        template(#header) Подготовка и сопровождение заявок по 44-ФЗ и 223-ФЗ
        .additional-offer-content
          p Юридически грамотное оформление документов для интересующих Вас закупок.
          p Подготовка и направление запросов разъяснений.
          p Подача заявки на тендерную площадку / сопровождение торгов.
          p Сопровождение заключения контракта в случае победы в закупке.
          p Консультации по получению обеспечения заявки / обеспечения контракта.
        template(#actions)
          ui-button(type="secondary" @click="selectOrder(1, 'Подготовка и сопровождение заявок по 44-ФЗ и 223-ФЗ')") Оставить заявку

tariff-order-dialog(
  v-model:show="showOrderDialog"
  :tariff="tariff"
  :period="periodDialog"
  :service-id="serviceId"
  :metric="metricDialog"
)
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { sendMetrica, YmGoals } from "~/utils/logger/metrica";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import { useLandingLogin } from "~/use/auth/useLandingLogin";
import { usePromo } from "~/use/other/usePromo";

import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import TheTariffSwitcher from "@/components/pages/settings/tariffPage/TheTariffSwitcher.vue";
import TariffColumn from "@/components/pages/settings/tariffPage/TariffColumn.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import TariffOrderDialog from "@/components/pages/settings/tariffPage/TariffOrderDialog.vue";
import Promotion from "~/components/pages/landing/Promotion.vue";

export default defineComponent({
  name: "TariffSelector",
  components: {
    Promotion,
    UiButton,
    TariffColumn,
    TheTariffSwitcher,
    SettingsPanel,
    TariffOrderDialog,
  },
  props: {
    hideLabels: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    const discount = ref(1)
    const period = ref(1)

    const showOrderDialog = ref(false);
    const tariff = ref('')
    const serviceId = ref(0)
    const periodDialog = ref(1)
    const metricDialog = ref('')

    const { showDialog } = useLandingLogin();
    const { promo } = usePromo();
    const { isAuth } = useUserAccess();

    const showPromo = computed(() => {
      if (!promo.value) return false;
      if (promo.value.newUsersOnly) return !isAuth.value;
      else return true
    })

    function setDiscount(event: { discount: number, period: number }) {
      discount.value = event.discount;
      period.value = event.period;
    }

    function selectTariff(s: number, t: string, p: number) {
      serviceId.value = s
      tariff.value = t
      periodDialog.value = p ? p : 0
      metricDialog.value = YmGoals.PlanGoal
      showOrderDialog.value = true
      sendMetrica(YmGoals.PlanClick)
    }

    function selectOrder(s: number, t: string) {
      serviceId.value = s
      tariff.value = t
      periodDialog.value = 0
      metricDialog.value = YmGoals.OrderGoal
      showOrderDialog.value = true
      sendMetrica(YmGoals.OrderClick)
    }

    return {
      isAuth,
      discount,
      period,
      showOrderDialog,
      tariff,
      periodDialog,
      metricDialog,
      serviceId,
      promo,
      showPromo,
      YmGoals,
      showDialog,
      setDiscount,
      selectTariff,
      selectOrder,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/landing/button';

.register-button {
  align-self: center;
}

.flex-column {
  display: flex;
  flex-flow: column;
  gap: 32px;
}

.m-t-32 {
  margin-top: 32px;
}

.tariff-page__list-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: var(--main-content-width);
  gap: 24px;
}

.settings-title {
  width: 100%;
  max-width: var(--main-content-width);
  color: var(--main-text-color);
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  text-transform: uppercase;
}

.tariff-page__additional-offers {
  display: grid;
  width: 100%;
  max-width: var(--main-content-width);
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-auto-rows: auto;
}

.additional-offer-content {
  display: flex;
  flex-flow: column;
  gap: 12px;
  font-size: 14px;
  height: 100%;

  p {
    margin: 0;
    padding: 0;
  }
}

.offer-paragraph {
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: auto 120px;
}

.offer-cost {
  font-size: 18px;
  color: var(--main-color-blue);
  white-space: nowrap;

  &._right {
    width: 120px;
    text-align: right;
  }
}

@media (max-width: 1000px) {
  .settings-title {
    font-size: 24px;
    line-height: 26px;
  }

  .offer-paragraph {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }

  .offer-cost {
    font-size: 14px;

    &._right {
      width: 100%;
      text-align: left;
    }
  }
}

@media (max-width: 860px) {
  .tariff-page__list-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
  .tariff-page__additional-offers {
    grid-column-gap: 16px;
    grid-row-gap: 24px;
  }
}

@media (max-width: 640px) {
  .settings-title {
    font-size: 20px;
    line-height: 22px;
  }

  .tariff-page__additional-offers {
    display: flex;
    flex-flow: column;
    gap: 12px;
  }

  .additional-offer-content {
    gap: 16px;
  }
}

@media (max-width: 460px) {
  .tariff-page__list-container {
    display: grid;
    grid-template-columns: 1fr;
  }
}

</style>
