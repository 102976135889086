<template lang="pug">
.search-by-filters
  search-form(
    tab="LotsListSearch"
    warning-type="newSearch"
    :edit-mode="false"
    :show-hide-button="false"
    :show-search-form="true"
    :has-filters-changes="hasFiltersChanges"
    v-model:searchForm="searchFormModel"
    v-model:activeTab="searchFormActiveTab"
    @start:search="fetchLots"
    @save:template="saveNewTemplate"
  )
  alert-message(
    v-if="showErrorMessage"
    closable
    type="error"
    label="Произошла ошибка при поиске по фильтрам"
    message="Пожалуйста, проверьте корректность заполнения фильтров, ключевых слов или профессионального поиска"
    @close="showErrorMessage = false"
  )
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref } from 'vue';
import { getDefaultLotsListSearchForm } from "~/utils/getters/defaultSearchForm";
import { validateSearchForm } from "~/utils/searchForm/searchFormWorker";

import SearchForm from "~/components/searchForm/SearchForm.vue";
import AlertMessage from "~/components/ui/alert/AlertMessage.vue";
import type { SearchFormI } from "~/stores/search/SearchFormInterface";

import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";
import { useVModel } from "@vueuse/core";

export default defineComponent({
  name: "SearchByFilters",
  components: {
    SearchForm,
    AlertMessage,
  },
  emits: [
    'update:searchForm',
    'template:create',
    'fetch:lots',
  ],
  props: {
    searchForm: {
      type: Object as PropType<SearchFormI>,
      default: () => ({}),
    },
    hasDefaultChanges: {
      type: Boolean,
      default: false,
    },
    hasFiltersChanges: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const showErrorMessage = ref(false);

    const searchFormModel = useVModel(props, 'searchForm', context.emit)
    const searchFormActiveTab = ref('default');

    const { showAlert } = useNotificationsStore();

    function validateFilters() {

      if (!props.hasDefaultChanges) {
        showAlert('Пустой запрос', 'Нет установленных фильтров', 6000)
        return false;
      }

      const notValid = validateSearchForm(searchFormModel.value)
      if (notValid) {
        showAlert('Ошибка валидации фильтров', notValid, 6000)
        return false
      }

      return true;
    }

    /** открытие диалога - сохранить новый шаблон */
    function saveNewTemplate() {
      if (validateFilters()) context.emit('template:create', searchFormModel.value)
    }

    /** поиск по фильтрам */
    function fetchLots() {
      if (validateFilters()) context.emit('fetch:lots', searchFormModel.value)
    }

    return {
      showErrorMessage,
      searchFormModel,
      searchFormActiveTab,
      fetchLots,
      saveNewTemplate,
    }
  }
})
</script>

<style scoped lang="scss">
.search-by-filters {
  display: flex;
  flex-flow: column;
  gap: 24px;

  margin-bottom: 40px;
}
</style>
