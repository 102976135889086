<template lang="pug">
.lot_auction_sites
  cleanable-title(@reset="fullCleanSelectedSites") ПЛОЩАДКИ ПРОВЕДЕНИЯ ТОРГОВ
  ui-multiselect.filter-card_input(
    show-count
    placeholder="Быстрый поиск по площадке"
    ref="select"
    v-model="modelValue.lotAuctionSitesIds"
    :count="meta?.info?.count || 0"
    :icon-right="UiIconNames.Icon_List"
    :options="meta.data"
    :loading="meta.info.loading"
    :no-data-description="meta.info.isOver ? `В нашей базе не найдено результатов, удовлетворяющих заданным критериям поиска` : ''"
    @search="getData(meta, $event)"
    @reset:options="onResetMeta"
    @click:icon="openSitesDialog = true"
  )
    template(#label="{ option }")
      span
        span {{ option.title }}
        a.sites-link(:href="option.link" target="_blank") {{ option.link }}

  tag-input.search_panel_frame(
    placeholder="Площадки не выбраны"
    :custom-class="customTagClass"
    :model-value="allTags"
    @delete="deleteSiteTag($event)"
  )
    template(#tag-label="{ tag, label }")
      span(style="display: block")
        | {{ tag.code && 'Группа '}} {{ label }}
        a.sites-link(v-if="tag.link" :href="tag.link" target="_blank") {{ tag.link }}

sites-dialog(
  v-model:show="openSitesDialog"
  v-model:searchForm="modelValue"
)
</template>

<script lang="ts">
import { defineAsyncComponent, ref, defineComponent, computed } from "vue";
import { useLawSite } from "@/use/searchForm/useLawSite";
import { useVModel } from "@vueuse/core";
import { getDefaultFilteredEntity } from "@/utils/getters/defaultRequestInfo";

import type { PropType } from "vue";
import type { FilteredEntity } from "@/utils/getters/defaultRequestInfo";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";

import UiMultiselect from "@/components/ui/select/multiselect/UiMultiselect.vue";
import TagInput from "@/components/tagInput/TagInput.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "SitesWrapper",
  components: {
    UiMultiselect,
    TagInput,
    CleanableTitle,
    SitesDialog: defineAsyncComponent(() => import("@/components/searchForm/dialogs/SitesDialog.vue")),
  },
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup(props, { emit }) {

    const searchForm = useVModel(props, 'modelValue', emit);

    const openSitesDialog = ref(false);
    const select = ref();
    const meta = ref<FilteredEntity>(getDefaultFilteredEntity());

    function fullCleanSelectedSites() {
      emit('reset', 'lotAuctionSitesIds')
      emit('reset', 'lotAuctionSitesGroups')
      emit('reset', 'withoutLotAuctionSite')
      meta.value = resetFieldMeta(meta.value)
      select.value.clearSearch();
    }

    function onResetMeta() {
      meta.value = resetFieldMeta(meta.value)
    }

    const allTags = computed(() => [
      ...searchForm.value.lotAuctionSitesGroups,
      ...(searchForm.value.withoutLotAuctionSite ? [{ id: 'without-sites', title: 'Без площадок проведения торгов' }] : []),
      ...searchForm.value.lotAuctionSitesIds,
    ])

    const {
      deleteSiteTag,
      getData,
      resetFieldMeta,
      customTagClass,
    } = useLawSite(searchForm);

    return {
      openSitesDialog,
      select,
      searchForm,
      meta,
      allTags,
      onResetMeta,
      customTagClass,
      deleteSiteTag,
      getData,
      fullCleanSelectedSites,
      UiIconNames,
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/links';
@import "@/assets/styles/mixin/searchForm";

.filter-card_input {
  @include filter-card-input;
}

.lot_auction_sites {
  width: 100%;
  max-width: var(--max-advanced-filters-width);
  display: flex;
  flex-flow: column;
  height: 100%;
  max-height: 300px;

  .sites-link {
    @include link-mixin;
    font-size: 12px;
    margin-left: 4px;
  }
}

.search_panel_frame {
  height: 100% !important;
}
</style>
