<template lang="pug">
component.back-breadcrumb(
  :class="bold && 'bold'"
  :is="isGoBack ? 'a' : (to ? defineNuxtLink({}) : 'span')"
  :to="to ? { name: to } : undefined"
  @click="isGoBack && goBack()"
)
  ui-icon(:icon="icon" :size="16")
  slot Вернуться назад
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import UiIcon from "~/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "GoBack",
  components: {
    UiIcon,
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  },
  props: {
    isGoBack: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: UiIconNames.Chevron_Left,
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
.back-breadcrumb {
  display: flex;
  flex-flow: row;
  gap: 12px;

  color: var(--main-color-breadcrumb);
  font-size: 14px;
  text-decoration: none;
  line-height: 18px;
  align-items: center;
  width: fit-content;

  &.bold {
    font-weight: bold;
    color: var(--main-color-black);
  }

  &:hover {
    cursor: pointer;
    color: #409eff;
  }
}
</style>
