import { computed } from "vue";
import { checkExpiredPlan, checkIsTmManager, isAccountBlocked } from "@/utils/authorization/auth";
import intersection from "lodash/intersection";
import useAuthStore from "@/stores/auth/useAuthStore";

import type { ManagerI, UserCompanyI } from "@/stores/auth/UserInterface";

// todo выписать все списки ролей в одно место, потому что если табу поиска (например) надо будет обновить, придется менять не только здесь но и в getRolesByRouteName
export function useUserAccess() {

  const config = useRuntimeConfig().public;
  const authStore = useAuthStore();

  const me = computed<ManagerI>(() => authStore.me)
  const company = computed<UserCompanyI>(() => authStore.company)
  const enabled = computed<string[]>(() => authStore.enabled || [])

  const isAuth = computed<boolean>(() => !!authStore.me?.id)
  const isTrial = computed<boolean>(() => authStore.isTrial || false)

  const isSuperAdmin = computed<boolean>(() => authStore.enabled.includes(config.TENMON_ADMIN));
  const isTmManager = computed<boolean>(() => checkIsTmManager(authStore.enabled));
  const isBlocked = computed<boolean>(() => isAccountBlocked(me.value, enabled.value));
  const isPlanExpired = computed<boolean>(() => !isBlocked.value && checkExpiredPlan(enabled.value))
  const isProduction = computed<boolean>(() => config.ENVIRONMENT === 'production')

  const roleAdmin = computed<boolean>(
    () => authStore.enabled.includes(config.ROLE_COMPANY_ADMIN)
  );

  const roleReadWriteLocal = computed<boolean>(
    () => authStore.enabled.includes(config.ROLE_READ_WRITE_LOCAL)
  )

  const roleReadOnly = computed<boolean>(
    () => authStore.enabled.includes(config.ROLE_READ_ONLY)
  )

  /** ENABLED - доступы в зависимости от тарифа */

  /** рассылка уведомлений */
  const enableSmsNotifications = computed<boolean>(() => authStore.enabled.includes(config.ENABLE_SMS_NOTIFICATIONS))
  const enableEmailNotifications = computed<boolean>(() => authStore.enabled.includes(config.ENABLE_EMAIL_NOTIFICATIONS))

  /** управление уровнями доступа пользователей */
  const enableAccessControl = computed<boolean>(() => authStore.enabled.includes(config.ENABLE_ACCESS_CONTROL))

  /** комментирование */
  const enableComments = computed<boolean>(
    () => authStore.enabled.includes(config.ENABLE_COMMENTS)
  );

  /** работа с шаблонами */
  const enableTemplates = computed<boolean>(
    () => intersection(
      authStore.enabled,
      [
        config.ENABLE_TEMPLATES,
        config.ENABLE_PRO_TEMPLATES,
        config.ENABLE_FILTER_TRASH_TENDERS,
        config.ENABLE_HIDDEN_TENDERS
      ]
    ).length > 0
  )

  /** сидит ли клиент ТОЛЬКО на экспертном поиске/экспертных шаблонах */
  const enableOnlyExpertTemplates = computed(() =>
    [config.ENABLE_FILTER_TRASH_TENDERS, config.ENABLE_HIDDEN_TENDERS].some(e => authStore.enabled.includes(e))
      && [config.ENABLE_TEMPLATES, config.ENABLE_PRO_TEMPLATES].every(e => !authStore.enabled.includes(e))
  )

  /** аналитика по отраслям */
  const enableAnalyticsIndustry = computed<boolean>(
    () => authStore.enabled.includes(config.ENABLE_ANALYTICS_INDUSTRY)
  );

  /** аналитика по заказчикам */
  const enableAnalyticsCustomers = computed<boolean>(
    () => authStore.enabled.includes(config.ENABLE_ANALYTICS_CUSTOMERS)
  );

  /** аналитика по поставщикам */
  const enableAnalyticsSuppliers = computed<boolean>(
    () => authStore.enabled.includes(config.ENABLE_ANALYTICS_SUPPLIERS)
  );

  const enableFastSearch = computed(() => authStore.enabled.includes(config.ENABLE_QUICK_SEARCH))

  /** управление заявками и контрактами */
  const enableLotManagement = computed<boolean>(
    () => authStore.enabled.includes(config.ENABLE_LOT_MANAGEMENT)
  );

  /** скачивание excel файла в поиске, заявках, контрактах */
  const enableDownloadLotsExcel = computed<boolean>(
    () => authStore.enabled.includes(config.ENABLE_SEARCH_EXCEL)
  )

  /** скачивание excel файла в аналитике  */
  const enableDownloadAnalyticsExcel = computed<boolean>(
    () => authStore.enabled.includes(config.ENABLE_ANALYTICS_EXCEL)
  );

  const enableAnalyticsTab = computed(() => enableAnalyticsIndustry.value || enableAnalyticsCustomers.value || enableAnalyticsSuppliers.value)
  const enableSearchTab = computed(() => intersection(
      authStore.enabled,
      [
        config.ENABLE_LOT_BASE,
        config.ENABLE_QUICK_SEARCH,
        config.ENABLE_TEMPLATES,
        config.ENABLE_PRO_TEMPLATES,
        config.ENABLE_FILTER_TRASH_TENDERS,
        config.ENABLE_HIDDEN_TENDERS,
      ]
    ).length > 0
  )

  return {
    me,
    company,
    isAuth,
    isTrial,
    isTmManager,
    isSuperAdmin,
    isBlocked,
    isPlanExpired,
    isProduction,
    roleAdmin,
    roleReadOnly,
    roleReadWriteLocal,
    enabled,
    enableFastSearch,
    enableOnlyExpertTemplates,
    enableComments,
    enableTemplates,
    enableAccessControl,
    enableSmsNotifications,
    enableEmailNotifications,
    enableLotManagement,
    enableSearchTab,
    enableAnalyticsTab,
    enableAnalyticsIndustry,
    enableAnalyticsCustomers,
    enableAnalyticsSuppliers,
    enableDownloadLotsExcel,
    enableDownloadAnalyticsExcel,
  }
}
