<template lang="pug">
.promo-details
  .label Данные о кампании
  .settings
    validation-row(not-empty required v-model="campaign.uid" label="Идентификатор" ref="refCampaignId" @error="errorChecker('campaign-id')" @correct="correctChecker('campaign-id')")
    validation-row(not-empty required v-model="campaign.title" label="Название" ref="refCampaignTitle" @error="errorChecker('campaign-title')" @correct="correctChecker('campaign-title')")
    ui-checkbox(v-model="campaign.isActual" label="Является ли рекламная кампания актуальной" id="is-campaign-actual")
    ui-button(style="width: fit-content;" :loader="isSaving" @click="validate") Сохранить
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useApi } from "@/use/api/useApi";
import { useForm } from "~/use/other/useForm";

import ValidationRow from "~/components/ui/form/input/ValidationRow.vue";
import UiButton from "~/components/ui/button/UiButton.vue";
import UiCheckbox from "~/components/ui/checkbox/UiCheckbox.vue";

import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";
import type { CampaignI } from "~/components/pages/admin/campaign/CampaignInterface";

export default defineComponent({
  name: "CampaignEditor",
  components: {
    ValidationRow,
    UiButton,
    UiCheckbox,
  },
  props: {
    data: {
      type: Object as PropType<CampaignI>,
      default: () => ({}),
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'success',
  ],
  setup(props, context) {

    const refCampaignId = ref();
    const refCampaignTitle = ref();

    const isSaving = ref(false);

    const campaign = ref(props.data);

    const { showError } = useNotificationsStore();
    const { validateForm, errorChecker, correctChecker } = useForm(saveChanges);

    /** сохранение изменений */
    function saveChanges() {
      isSaving.value = true;

      (props.isCreate
        ? useApi().campaigns.postCampaign(campaign.value)
        : useApi().campaigns.patchCampaign(campaign.value?.id, { uid: campaign.value?.uid, title: campaign.value?.title, isActual: campaign.value?.isActual }))
          .then((response: any) => context.emit('success', response))
          .catch(() => showError(props.isCreate ? 'Не удалось создать кампанию' : 'Не удалось обновить кампанию'))
          .finally(() => isSaving.value = false)
    }

    function validate() {
      refCampaignId.value?.validate()
      refCampaignTitle.value?.validate()

      validateForm()
    }

    return {
      campaign,
      isSaving,
      refCampaignId,
      refCampaignTitle,
      errorChecker,
      correctChecker,
      validate,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';

.error-message {
  @include error-message;
}

.promo-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;

  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid #e1e2e6;
  }

  .label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }

  .settings {
    display: flex;
    flex-flow: column;
    gap: 16px;
  }
}
</style>
