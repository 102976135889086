import lodashSet from "lodash/set";
import lodashGet from "lodash/get";
import type { Ref } from "vue";
import type { FilteredEntity } from "@/utils/getters/defaultRequestInfo";
import type { BaseSearchFormInterface, TabMode, TagWrapperMode } from "@/stores/search/SearchFormInterface";
import cloneDeep from "lodash/cloneDeep";
import {
  getCommonForm, getDefaultAnalyticsIndustryForm,
  getDefaultLotsListSearchForm,
  getDefaultSearchForm
} from "@/utils/getters/defaultSearchForm";
import { preRequest, afterRequest } from "@/utils/api/searchRequests";
import { useAbort, useApi } from "@/use/api/useApi";
import { getDefaultFilteredEntity } from "@/utils/getters/defaultRequestInfo";

interface FilteredEntityResponseI {
  count: number;
  data: any[];
}

export function useSearchFormCommon() {

  function getUrlByMode(mode: TagWrapperMode) {
    switch (mode) {
      case 'suppliers': return 'suppliers-search';
      case 'customers': return 'customers-search';
      case 'sites': return 'site';
      case 'okpd': return 'okpd';
    }
  }

  function getFilteredEntity(meta: FilteredEntity, mode: TagWrapperMode, payload: any) {

    const { signal, abort, } = useAbort();

    preRequest(meta, abort);

    useApi().filtered.fetchFilteredEntity<FilteredEntityResponseI>(getUrlByMode(mode), payload, signal)
      .then((response) => {
        const data: any = response || {}

        meta.info.count = data?.count;
        meta.info.isOver = data?.data?.length === 0;

        if (mode === 'customers' || mode === 'suppliers') meta.data = (data?.data || []).map((c: any) => { return { id: c.id, inn: c.inn, kpp: c.kpp, title: c.shortTitle ? c.shortTitle : c.fullTitle }})
        else meta.data = data?.data || []

        afterRequest(meta, false, meta.info.count);
      })
      .catch((error) => {
        if (!signal.aborted) {
          afterRequest(meta, true)
          meta.data = []
        }
      })
  }

  function resetFieldMeta(meta: FilteredEntity) {
    if (meta.info.request) meta.info.request.cancel();
    return getDefaultFilteredEntity();
  }

  function resetCurrentField<T extends BaseSearchFormInterface>(_searchForm: Ref<T>, tab: TabMode, field: string): void {
    lodashSet(
      _searchForm.value,
      field.split("."),
      cloneDeep(lodashGet(getDefaultSearchForm(tab), field.split(".")),
      ))
  }

  /**
   * для того, чтобы при сбросе фильтров не стирались поля kpp, inn, companyId
   */
  function resetSearchForm(_searchForm: Ref, tab: TabMode) {
    switch (tab) {
      case 'LotsListSearch':
        _searchForm.value = getDefaultLotsListSearchForm()
        break;
      case 'AnalyseIndustry':
        _searchForm.value = getDefaultAnalyticsIndustryForm();
        break;
      case 'AnalyseCustomers':
      case 'AnalyseSuppliers':
        _searchForm.value = Object.assign(_searchForm.value, getCommonForm())
        break;
    }
  }

  return {
    getFilteredEntity,
    resetFieldMeta,
    resetCurrentField,
    resetSearchForm,
  }
}
