<template lang="pug">
.tariff-column-header
  .tariff-column__hit(:class="!hit && '_empty'")
    template(v-if="hit || hitLabel") {{ hitLabel || 'ХИТ' }}
  .tariff-column__title {{ title }}
  .tariff-column__description
    span {{ description }}
    span._info {{ descriptionHint }}
  .tariff-column__cost.cross(v-if="promoPriceRule && !promoForNewUsers")
    span._small от
    span {{ costTariff().format(price) }}
    span._small ₽
  .tariff-column__cost
    span._small от
    span {{ costTariff().format(promoPriceRule && !promoForNewUsers ? promoDiscountCost : price) }}
    span._small ₽
    old-warning(v-if="priceHint" :label="priceHint")
  .promo-description(v-if="promoPriceRule && promoForNewUsers && !isAuth")
    span.description Стоимость по <b>акции</b> для новых пользователей соcтавляет
    span.cost
      span._small от
      span {{ costTariff().format(promoDiscountCost) }}
      span._small ₽
  ui-button(type="secondary" :class="hit && 'hit-button'" @click="$emit('selectTariff')") Выбрать план
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { costTariff } from "~/utils/formatter/costFormatter";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import OldWarning from "~/components/ui/tooltip/OldWarning.vue";
import UiButton from "~/components/ui/button/UiButton.vue";

import type { PropType } from "vue";
import type { PromoRuleI } from "~/use/other/usePromo";

export default defineComponent({
  name: "TariffColumnHeader",
  methods: { costTariff },
  components: {
    UiButton,
    OldWarning,
  },
  emits: [
    'selectTariff',
  ],
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    descriptionHint: {
      type: String,
      required: true,
    },
    hit: {
      type: Boolean,
      default: false,
    },
    hitLabel: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: 0,
    },
    priceHint: {
      type: String,
      default: '',
    },
    promoPriceRule: {
      type: Object as PropType<PromoRuleI|undefined>,
      default: undefined,
    },
    promoForNewUsers: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const { isAuth } = useUserAccess();

    const promoDiscountCost = computed(() => {
      if (!props.promoPriceRule) return undefined
      return props.promoPriceRule?.isRubles ? props.promoPriceRule?.value : Math.trunc((props.price * (1 - (props.promoPriceRule?.value/100)))/100)*100
    })

    return {
      isAuth,
      promoDiscountCost,
    }
  }
})
</script>

<style scoped lang="scss">
$cost-size: 30px;
$cost-height: 36px;
$cost-size-small: 20px;

.tariff-column-header {
  display: flex;
  flex-flow: column;
  gap: 16px;

  align-items: center;
  text-align: center;
  padding: 32px 20px;

  .tariff-column__hit {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: var(--main-red-color);
    text-transform: uppercase;
    height: 20px;
  }

  .tariff-column__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
  }

  .tariff-column__description {
    display: flex;
    flex-flow: column;
    font-size: 16px;
    line-height: 20px;
    gap: 16px;
    height: 120px;
    font-weight: 500;

    &._small {
      height: 56px;
    }

    span {
      font-weight: 500;

      &._info {
        font-size: 14px;
        line-height: 18px;
        padding: 0 8px;
        color: #909399;
      }
    }
  }

  .tariff-column__cost {
    font-weight: 700;
    font-size: $cost-size;
    line-height: $cost-height;
    display: flex;
    flex-flow: row;
    gap: 8px;
    align-items: baseline;

    span._small {
      font-size: $cost-size-small;
    }
  }
}

.hit-button {
  color: #ed7a1d !important;
  border: 1px solid #FFC310 !important;

  &:hover {
    background-color: #fff6ec !important;
  }
}

.promo-description {
  display: flex;
  flex-flow: column;
  gap: 8px;

  font-size: 14px;
  line-height: 16px;
  padding: 0 8px;
  color: #F56C6C;
  align-items: center;

  .cost {
    font-weight: 700;
    font-size: $cost-size;
    line-height: $cost-height;
    display: flex;
    flex-flow: row;
    gap: 8px;
    align-items: baseline;

    span._small {
      font-size: $cost-size-small;
    }
  }
}

.cross {
  position: relative;
  text-decoration: none;
  font-size: 20px !important;
  font-weight: 600;

  span._small {
    font-size: 14px !important;
  }

  &:before {
    content: "";
    border-bottom: 2px solid red;
    position: absolute;
    width: 100%;
    height: 50%;
    transform: rotate(-12deg);
  }
}

@media (max-width: 1000px) {
  .tariff-column-header {
    .tariff-column__title {
      font-size: 18px;
    }
    .tariff-column__description {
      font-size: 14px;
      line-height: 18px;
    }
    .tariff-column__cost {
      font-size: 26px;
      &._small {
        font-size: 18px;
      }
    }
    .promo-description {
      .cost {
        font-size: 26px;
        span._small {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 860px) {
  .tariff-column-header {
    .tariff-column__description {
      height: 120px;
    }
  }
}

@media (max-width: 640px) {
  .tariff-column-header {
    .tariff-column__description {
      height: 160px;
    }
  }
}

@media (max-width: 460px) {
  .tariff-column-header {
    .tariff-column__hit {
      &._empty {
        display: none;
      }
    }
    .tariff-column__description {
      height: auto;

      &._small {
        height: auto;
      }
    }
  }
}
</style>
