import groupBy from "lodash/groupBy";
import { computed } from "vue";
import type { Ref } from "vue";
import type { FilteredEntity } from "@/utils/getters/defaultRequestInfo";

export function floatToFixed(value: number, fixed = 2) {
  return parseFloat((value || 0).toFixed(fixed));
}

function getSumFromObjects(arr: any, prop: any) {
  return arr?.reduce((a: any, curr: any) => a + parseFloat(curr[prop]) || 0, 0);
}

export function getValue(data: any, param: any) {
  return floatToFixed(getSumFromObjects(data, param));
}

export function useStatistics(lineChartData: Ref<FilteredEntity>) {

  /** transform array of dates to array of object with keys 'year' & data - dates in this year */
  const byYear = computed(() => {
    return groupBy(lineChartData.value.data, (element: any) => {
      return element.date.substring(0, 4);
    });
  })

  /** array of years (descending) of line-chart data  */
  const listOfYears = computed(() => Object.keys(byYear.value).sort((a: any, b: any) => { return b - a }))

  /** summary data by same years for line-chart */
  const summaryByYears = computed(() => {
    return listOfYears.value.map((e: any) => {
      return {
        date: e,
        lotSummary: getValue(byYear.value[e] || [], 'lotSummary'),
        total: getValue(byYear.value[e] || [], 'total'),
        win: getValue(byYear.value[e] || [], 'win'),
        winSummary: getValue(byYear.value[e] || [], 'winSummary'),
        currency: byYear.value[e][0]?.currency,
      }
    })
  })

  return {
    byYear,
    listOfYears,
    summaryByYears,
  }
}
