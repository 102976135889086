import { ref } from "vue";
import { useApi } from "@/use/api/useApi";
import type { BaseObjectI } from "~/stores/manuals/ManualsInterface";

export interface PromoRuleI {
  value: string,
  isRubles: boolean,
}

export interface PromoI {
  id: number | null,
  serviceTitle: string,
  landingPromoTitle: string,
  landingPromoAgreement: string,
  promoTitle: string,
  promoDescription: string,
  promoAgreement: string,
  rules: Record<string, PromoRuleI>,
  newUsersOnly: boolean,
  dateCreate: string | null,
  dateStart: string | null,
  dateFinish: string | null,
  isActual: boolean,
  periodType: number,    // периодизация акции - ежегодная/ежеквартальная/единичная
}

interface RuleResponseI {
  tariff: BaseObjectI,
  tariffDuration: number,
  value: string,
  isRubles: boolean,
}

interface PromoPeriodI {
  id: number,
  title: number,
  isSupported: boolean,
}

function validateRules(r: RuleResponseI[]) {
  let res : Record<string, PromoRuleI> = {}
  r.forEach(rule => {
    res[`${rule?.tariff?.id}-${rule.tariffDuration}`] = {
      value: rule.value,
      isRubles: rule.isRubles,
    }
  })
  return res;
}

const promo = ref<PromoI>();
const promoPeriodTypes = ref<PromoPeriodI[]>([]);

export function usePromo() {

  /** функция для валидации респонса акции с бека */
  function validateResponse(response: any) : PromoI {
    return {
      ...response,
      periodType: response?.periodType?.id || 1,
      rules: validateRules(response?.rules || []),
    }
  }

  /** получение актуальной акции для отображения на лендинге и в тарифах */
  function getActualPromoDetails() {
    useApi().promo.fetchActualPromo()
      .then((response: any) => {
        if (response && response.length) promo.value = validateResponse(response[0])
      })
      .catch(() => {})
  }

  /** получение списка периодизаций акций */
  function getPromoPeriod() {
    useApi().promo.fetchPromoPeriodTypes()
      .then((response: any) => promoPeriodTypes.value = response)
  }

  function getDefaultPromoData() : PromoI {
    return {
      id: null,
      serviceTitle: '',
      landingPromoTitle: '',
      landingPromoAgreement: '',
      promoTitle: '',
      promoDescription: '',
      promoAgreement: '',
      rules: {},
      newUsersOnly: true,
      dateCreate: '',
      dateStart: '',
      dateFinish: '',
      isActual: false,
      periodType: 1,
    }
  }

  return {
    promo,
    promoPeriodTypes,
    getPromoPeriod,
    validateResponse,
    getActualPromoDetails,
    getDefaultPromoData,
  }
}
