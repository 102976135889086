<template lang="pug">
settings-panel
  template(#header) Список менеджеров
  template(#headerActions)
    .link(@click="openInviteDialog") Создать аккаунт менеджера
  ui-table(
    :data="adminsList"
    :loader="adminsLoading"
    :columns="columns"
    :has-error="adminsError"
    :header-sticky="true"
    :small-cell="true"
    no-data-text="Список менеджеров пуст"
    no-data-description="none"
  )
    template(#fio="{ rowData }")
      .bd(:style="rowData.id === me.id && 'font-weight: bold;'")
        .value {{ rowData.fio || '-' }}
        .badge(v-if="rowData.id === me.id")
    template(#email="{ rowData }") {{ rowData.login || '-' }}
    template(#role="{ rowData }") {{ rowData.role?.title || '-' }}
    template(#status="{ rowData }")
      .value(:class="!rowData.isActual && 'red'") {{ rowData.isActual ? 'Активный' : 'Заблокирован' }}
    template(#actions="{ rowData }")
      .value.actions
        icon-button(
          v-if="me.id !== rowData.id"
          :size="16"
          :icon="rowData.isActual ? UiIconNames.Icon_Unlock : UiIconNames.Icon_Lock"
          :tooltip-title="rowData.isActual ? 'Заблокировать' : 'Разблокировать'"
          @click="openBlockDialog(rowData)"
        )
        icon-button(
          :size="16"
          :icon="UiIconNames.Icon_Mail"
          tooltip-title="Редактировать email менеджера"
          @click="openEditMailDialog(rowData)"
        )
        icon-button(
          :size="16"
          :icon="UiIconNames.Icon_Edit"
          tooltip-title="Редактировать данные менеджера"
          @click="openEditDialog(rowData)"
        )

confirm-block-dialog(
  v-if="showBlockAdminDialog"
  v-model:show="showBlockAdminDialog"
  :user="activeManager"
  :mode="activeManager?.isActual ? 'lock' : 'unlock'"
  @success="blockManager"
)
email-modifier-dialog(
  v-if="showEmailModifierDialog"
  v-model:show="showEmailModifierDialog"
  :user="activeManager"
  :is-admin-mode="true"
)
user-modifier-dialog(
  v-if="showUserModifierDialog"
  v-model:show="showUserModifierDialog"
  :user="activeManager"
  :is-admin-mode="true"
  :is-create="isCreateMode"
)
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, ref } from 'vue'
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { storeToRefs } from "pinia";

import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import IconButton from "@/components/ui/button/IconButton.vue";
import UiTable from "~/components/ui/table/UiTable.vue";
import type { AdminI } from "@/stores/auth/UserInterface";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import useManualsStore from "@/stores/manuals/useManualsStore";
import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";

export default defineComponent({
  name: "ManagersList",
  components: {
    UiTable,
    IconButton,
    SettingsPanel,
    UserModifierDialog: defineAsyncComponent(() => import("@/components/pages/settings/userManagementPage/UserModifierDialog.vue")),
    EmailModifierDialog: defineAsyncComponent(() => import("@/components/pages/settings/userManagementPage/EmailModifierDialog.vue")),
    ConfirmBlockDialog: defineAsyncComponent(() => import("@/components/pages/admin/managers/ConfirmBlockDialog.vue")),
  },
  setup() {

    const { me } = useUserAccess();

    const showUserModifierDialog = ref(false);
    const showEmailModifierDialog = ref(false);
    const showBlockAdminDialog = ref(false);

    const activeManager = ref<AdminI|null>({});
    const isCreateMode = ref(false);

    const manualsStore = useManualsStore();
    const { adminsRolesList } = storeToRefs(manualsStore);

    const managerStore = useTmManagerStore();
    const {
      adminsList,
      adminsError,
      adminsLoading,
    } = storeToRefs(managerStore);

    manualsStore.manuals.getAdminsRoles()
    if (!adminsLoading.value) managerStore.getAdminsList()

    function openBlockDialog(admin: AdminI) {
      activeManager.value = admin
      showBlockAdminDialog.value = true;
    }

    function openEditDialog(admin: AdminI) {
      isCreateMode.value = false
      activeManager.value = admin
      showUserModifierDialog.value = true;
    }

    function openEditMailDialog(admin: AdminI) {
      activeManager.value = admin
      showEmailModifierDialog.value = true;
    }

    function openInviteDialog() {
      isCreateMode.value = true
      showUserModifierDialog.value = true;
    }

    /** отобразить что пользователь заблокирован (on success patch) */
    function blockManager() {
      if (activeManager.value) activeManager.value.isActual = !activeManager.value.isActual;
    }

    const columns = {
      fio: { size: 314, headerLabel: 'ФИО' },
      email: { size: 282, headerLabel: 'Email' },
      role: { size: 216, headerLabel: 'Роль' },
      status: { size: 162, headerLabel: 'Статус' },
      actions: { size: 100 },
    }

    return {
      me,
      columns,
      isCreateMode,
      adminsList,
      adminsError,
      adminsLoading,
      activeManager,
      showUserModifierDialog,
      showEmailModifierDialog,
      showBlockAdminDialog,
      openBlockDialog,
      openEditDialog,
      openEditMailDialog,
      openInviteDialog,
      blockManager,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/links";

.red {
  color: var(--main-red-color);
}

.bd {
  display: flex;
  flex-flow: row;

  .badge {
    width: 4px;
    height: 4px;
    background-color: var(--main-color-blue);
    border-radius: 50%;
    display: flex;
    align-self: center;
    margin-left: 4px;
    flex-shrink: 0;
  }
}

.link {
  @include link-mixin;

  &:hover {
    color: var(--main-color-blue-dark);
  }
}

.value {
  overflow-wrap: anywhere;
}

.actions {
  display: flex;
  flex-flow: row;
  gap: 0;
  margin-left: auto;
}
</style>
