import pick from "lodash/pick";
import { ref, nextTick } from "vue";
import { defineStore } from "pinia";
import { redirectAfterLogin, resetLocalUser, setLocalUser } from "@/router/auth";
import { useApi } from "@/use/api/useApi";
import { getFullName } from "@/utils/formatter/nameFormatter";
import { useRoute } from "vue-router";
import { onLogout, prepareEnabled, redirectAfterReset } from "@/utils/authorization/auth";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";
import { getDifferenceDays } from "~/utils/formatter/dateFormatter";

import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";
import { Tabs } from "@/router/tabs";

import type { Ref } from "vue";
import type { LoginI } from "@/use/api/ApiInterface";
import type {
  ManagerI,
  UserCompanyI,
  AuthorizedUserI
} from "@/stores/auth/UserInterface";

function getDefaultUser(): ManagerI {
  return {
    id: NaN,
    fio: "",
    firstName: "",
    middleName: "",
    surName: "",
    phone: "",
    login: "",
    timezone: null,
    role: null,
  }
}

function getDefaultCompany(): UserCompanyI {
  return {
    id: NaN,
    title: '',
    dateEndTariff: '',
    tariff: null,
  }
}

export const awaitUser = ref<any>();

/** стор синхронизуется с localStorage самостоятельно (persistedstate) */
const useAuthStore = defineStore('authStore', () => {

  const me = ref<ManagerI>(getDefaultUser())
  const company = ref<UserCompanyI>(getDefaultCompany())
  const enabled = ref<string[]>([])

  const isTrial = ref<boolean>(false)
  const campaign = ref<string>('');

  const route = useRoute();

  if (me.value.id) setLocalUser(me.value, enabled.value)

  const { storage } = useServiceStorage();

  function resetUser() {
    me.value = getDefaultUser()
    company.value = getDefaultCompany()
    enabled.value = []
  }

  function setUserData(user: AuthorizedUserI) {
    company.value = user.company
    enabled.value = user.enabled || []

    try {
      isTrial.value = user.dateCreate ? getDifferenceDays(user.dateCreate, new Date()) <= 7 : false
    } catch {
      isTrial.value = false
    }

    me.value = {
      ...pick(user, ['login', 'id', 'firstName', 'middleName', 'surName', 'phone', 'role', 'timezone', 'isActual']),
      fio: getFullName(user),
    }

    setLocalUser(me.value, enabled.value)
  }

  function login(payload: LoginI) {
    storage.onLogin = true;

    return new Promise((resolve, reject) => {

      useApi().security.fetchLogin(payload)
        .then(() => resolve(null))
        .catch(() => reject('Указан неверный логин или пароль'))

    })
  }

  function getUsersMe(afterLogin = false) {
    if (!awaitUser.value || afterLogin) {
      awaitUser.value = useApi().security.getMe()
        .then((data) => {
          const innerUser: AuthorizedUserI = {
            ...data,
            enabled: prepareEnabled(data.enabled || []),
          }

          setUserData(innerUser)
          if (afterLogin || route.name === Tabs.Landing) redirectAfterLogin()
        })
        .catch(() => {})
        .finally(() => awaitUser.value = undefined)
    }

    return awaitUser.value;
  }

  function logout(loader: Ref) {
    loader.value = true;

    useApi().security.fetchLogout()
      .then(() => {
        redirectAfterReset();
        nextTick(() => setTimeout(() => onLogout(), 1000));
      })
      .catch(() => useNotificationsStore().showError('При выходе из аккаунта произошла ошибка, пожалуйста, повторите попытку через несколько минут'))
      .finally(() => loader.value = false);
  }

  function resetStore() {
    resetUser()
  }

  return {
    me,
    campaign,
    company,
    enabled,
    isTrial,
    login,
    getUsersMe,
    logout,
    resetStore,
  }
}, { persist: true })

export default useAuthStore;
