<template lang="pug">
.regions-tree
  cleanable-title(@reset="cleanFilter") {{ title ? title : "РЕГИОН" }}
  base-input.filter-card_input(
    clearable
    v-model="filterText"
    placeholder="Быстрый поиск по региону"
  )
  .tree-wrapper
    el-tree(
      ref="regionTree"
      show-checkbox
      node-key="id"
      :expand-on-click-node="true"
      :data="regions"
      :props="{ children: 'children', label: 'title', class: customNodeClass }"
      :default-checked-keys="modelValueToKeys(modelValue[storeVariableName])"
      :filter-node-method="filterRegions"
      @check="check"
    )
</template>

<script lang="ts">
import { defineComponent, watch, ref } from "vue";
import { useVModel } from "@vueuse/core";
import { storeToRefs } from "pinia";

import type { ElTree } from 'element-plus'
import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";

import BaseInput from "@/components/ui/base/BaseInput.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import useManualsStore from "@/stores/manuals/useManualsStore";

interface Tree {
  id: number;
  title: string;
  shortTitle: string | undefined;
  children: Tree[] | undefined;
  firstLeaf?: boolean;
}

export default defineComponent({
  name: "RegionsTree",
  components: {
    BaseInput,
    CleanableTitle,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
    storeVariableName: {
      type: String as PropType<'places'|'deliveryPlaceIds'>,
      default: 'places',
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup(props, context) {

    const manualsStore = useManualsStore()
    const { regions } = storeToRefs(manualsStore)

    const filterText = ref("");
    const regionTree = ref<InstanceType<typeof ElTree>>();

    const searchForm = useVModel(props, 'modelValue', context.emit)

    watch(filterText, (newValue: string) => {
      if (regionTree.value) regionTree.value.filter(newValue);
    });

    watch(() => props.modelValue[props.storeVariableName], (val: any[]) => {
      if (regionTree.value) regionTree.value.setCheckedKeys(modelValueToKeys(val || []), false);
    });

    function modelValueToKeys(val: any[]) {
      if (props.storeVariableName === 'deliveryPlaceIds') return val || [];
      if (props.storeVariableName === 'places') {
        let newValue : number[] | string[] = []
        val.forEach(d => {
          if (d.regionIds.length === 0) newValue.push('district_' + d.districtId)
          else newValue.push(...d.regionIds)
        })
        return newValue || []
      }
    }

    const customNodeClass = (data: Tree) => {
      if (data.firstLeaf) return "first-leaf";
      if (data.children?.length) return "inner-leaf";
      return null;
    }

    function filterRegions(value: any, data: any) {
      if (!value) return true;
      return data.title.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    }

    function cleanFilter() {
      context.emit('reset', props.storeVariableName)
      if (regionTree.value) regionTree.value.setCheckedKeys([], false)
    }

    function check() {
      if (props.storeVariableName === 'deliveryPlaceIds') searchForm.value.deliveryPlaceIds = regionTree.value?.getCheckedKeys(true)?.filter((x: any) => typeof x === 'number') as number[] || [];
      else {
        let obj: any[] = []
        let districtsFull = (regionTree.value?.getCheckedNodes(false, false) || []).filter(e => e.hasOwnProperty('districtId')).map(e => e.districtId)
        let districtsHalf = (regionTree.value?.getCheckedNodes(false, true) || []).filter(e => e.hasOwnProperty('districtId') && !districtsFull.includes(e.districtId)).map(e => e.districtId)

        let regionsFromHalfDistrict = (regionTree.value?.getCheckedNodes(true, false) || []).filter(e => !districtsFull.includes(e.parentId))

        districtsFull.forEach(id => obj.push({districtId: id, regionIds: []}))
        districtsHalf.forEach(id => obj.push({
          districtId: id,
          regionIds: regionsFromHalfDistrict.filter(r => r.parentId === id).map(r => r.id)
        }))

        searchForm.value.places = obj
      }
    }

    return {
      regions,
      regionTree,
      filterText,
      filterRegions,
      customNodeClass,
      cleanFilter,
      check,
      modelValueToKeys,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/searchForm";

.regions-tree {
  width: 100%;
}

.filter-card_input {
  @include filter-card-input;
}

.tree-wrapper {
  @import "@/assets/styles/searchForm/searchFormTree";
  @include tree-wrapper;
}
</style>
