<template lang="pug">
#services
  .page-title__wrapper._margin
    h1.main-page-title ИСТОРИЯ ЗАКАЗАННЫХ УСЛУГ
    .actions
      ui-button(v-if="hasFilters" type="secondary" @click="resetFilters") Очистить фильтры и сортировки
      ui-button(v-if="!isTmManager" @click="openTenderService(undefined)") Заказать тендерные услуги
  .service-page__content
    order-history-table(
      :data="data"
      :loader="loader"
      :has-error="error"
      :has-filters="hasFilters"
      v-model:filters="preFiltrationForm"
      @apply-filters="jumpToPage(1)"
    )
    ui-pagination(
      :count-results="data.length"
      :total-results="totalResults"
      :current-page="preFiltrationForm.page"
      :total="totalPages"
      @update:current-page="jumpToPage($event, false)"
    )
  service-advertising.service-ad(v-if="showAd & !isTmManager")
</template>

<script lang="ts">
import { ref, defineComponent, watch, computed } from "vue";
import { useApi } from "@/use/api/useApi";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useTenderService } from "@/use/tenderService/useTenderService";
import { getDefaultPreFiltrationFormService } from "@/utils/getters/defaultFilters";
import { storeToRefs } from "pinia";

import UiButton from "@/components/ui/button/UiButton.vue";
import UiPagination from "@/components/ui/pagination/UiPagination.vue";
import OrderHistoryTable from "@/components/pages/services/OrderHistoryTable.vue";
import ServiceAdvertising from "@/components/pages/services/ServiceAdvertising.vue";

import type { OrderedTenderI } from "@/use/tenderService/TenderServiceInterface";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";

export default defineComponent({
  name: "TenderServiceIndex",
  components: {
    UiButton,
    UiPagination,
    OrderHistoryTable,
    ServiceAdvertising,
  },
  setup() {

    const data = ref<OrderedTenderI[]>([]);
    const error = ref(false);

    const showAd = ref(false);
    const loader = ref(true);

    const totalPages = ref(1);
    const totalResults = ref(0);

    const tenmonManagerStore = useTmManagerStore();
    const { selectedCompanyInfo } = storeToRefs(tenmonManagerStore);

    const { isTmManager } = useUserAccess();
    const { openTenderService, needReload } = useTenderService();

    const defaultForm = {
      ...getDefaultPreFiltrationFormService(),
      sorting: [{ title: 'dateCreate', order: 'desc' }]
    }

    const preFiltrationForm = ref({
      ...defaultForm,
      customerCompany: selectedCompanyInfo.value,
    });

    const hasFilters = computed(() =>
      selectedCompanyInfo.value
      ? !isEqual(omit(preFiltrationForm.value, ['page', 'customerCompany']), omit(defaultForm, ['page', 'customerCompany']))
      : !isEqual(omit(preFiltrationForm.value, ['page']), omit(defaultForm, ['page']))
    )

    /** обновление результатов, если был сделан заказ со страницы услуг */
    watch(needReload, (val) => {
      if (val) {
        jumpToPage(1)
        needReload.value = false
      }
    })

    /** обновление результатов, если менеджером был выбран клиент */
    if (isTmManager.value) {
      watch(selectedCompanyInfo, resetFilters)
    }

    /** получение списка заказов */
    useApi().orders.fetchOrdersList(preFiltrationForm.value)
      .then((hydraData: any) => {
        totalResults.value = Number(hydraData['totalItems'])
        totalPages.value = Math.ceil(hydraData['totalItems'] === 0 ? 1 : (hydraData['totalItems'] / 30))
        data.value = hydraData['member']
      })
      .catch(() => error.value = true)
      .finally(() => {
        showAd.value = !data.value.length
        loader.value = false;
      });

    /** фильтрация заказов */
    function jumpToPage(page: number, resetPages = true) {
      preFiltrationForm.value.page = page;
      applyFilters(resetPages)
    }

    function applyFilters(resetPages: boolean) {
      loader.value = true;

      if (resetPages) {
        totalResults.value = 0
        totalPages.value = 1
      }

      data.value = []
      error.value = false

      useApi().orders.fetchOrdersList(preFiltrationForm.value)
        .then((filtersData: any) => {
          totalResults.value = Number(filtersData['totalItems'])
          totalPages.value = Math.ceil(filtersData['totalItems'] === 0 ? 1 : (filtersData['totalItems'] / 30))
          data.value = filtersData['member']
          if (data.value.length) showAd.value = false
        })
        .catch(() => error.value = true)
        .finally(() => loader.value = false);
    }

    function resetFilters() {
      preFiltrationForm.value = {
        ...defaultForm,
        customerCompany: selectedCompanyInfo.value || null,
      }
      jumpToPage(1)
    }

    return {
      data,
      error,
      loader,
      showAd,
      isTmManager,
      totalPages,
      totalResults,
      preFiltrationForm,
      hasFilters,
      resetFilters,
      jumpToPage,
      applyFilters,
      openTenderService,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";
@import "@/assets/styles/pages/main";

#services {
  @include page-view;
}

.service-page__content {
  display: flex;
  flex-flow: column;
  gap: 24px;

  padding: 12px 16px;
  background-color: white;

  border-radius: 4px;
  border: 1px solid #F1F0F0;
}

.actions {
  display: flex;
  flex-flow: row;
  gap: 8px;
}

.service-ad {
  margin-top: 32px;
}
</style>
