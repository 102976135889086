<template lang="pug">
.outer(:class="isAuth && 'fit-width'")
  .promotion
    .promo-title {{ promo.promoTitle }}
    .promo-description(v-if="promo.promoDescription") {{ promo.promoDescription }}
    .promo-agreement(v-if="promo.promoAgreement") {{ promo.promoAgreement }}
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import type { PromoI } from "~/use/other/usePromo";

export default defineComponent({
  name: "Promotion",
  props: {
    promo: {
      type: Object as PropType<PromoI>,
      default: () => ({}),
    },
  },
  setup() {

    const { isAuth } = useUserAccess();

    return {
      isAuth,
    }
  }
})
</script>

<style scoped lang="scss">
.promotion {
  display: flex;
  flex-flow: column;
  gap: 16px;

  border-radius: 6px;
  //background: linear-gradient(45deg, #ffd9d9, #dee8ff);
  background: var(--default-blue-color);
  max-width: 1140px;

  color: white;
  width: 100%;
  padding: 32px 24px;
  box-sizing: border-box;
  align-items: center;

  .promo-title {
    font-size: 24px;
    font-weight: 600;
  }

  .promo-description {
    font-size: 16px;
    line-height: 20px;
  }

  .promo-agreement {
    font-size: 12px;
  }
}

.outer {
  width: 100vw;
  display: flex;
  align-self: center;
  background: var(--default-blue-color);
  justify-content: center;

  &.fit-width {
    width: 100%;
  }
}

@media (max-width: 860px) {
  .promotion {
    .promo-title {
      font-size: 20px;
    }

    .promo-description {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
