<template lang="pug">
.tariff-column(:class="hit && '_hit'")
  tariff-column-header.header(
    :title="title"
    :description="description"
    :description-hint="descriptionHint"
    :hit="hit"
    :hit-label="hitLabel"
    :price="price"
    :price-hint="priceHint"
    :promo-price-rule="promoPriceRule"
    :promo-for-new-users="promo?.newUsersOnly"
    @select-tariff="$emit('selectTariff')"
  )
  .tariff-column__content
    .tariff-column__feature-block(
      v-for="feature in features"
      :key="feature.id"
    )
      .tariff-column__feature-block-title {{ feature.title }}
      .tariff-column__feature-item(
        v-for="subFeature in feature.subFeatures"
        :key="subFeature.id"
      )
        div(:class="!includeOptions.includes(subFeature.id) && 'hidden'") {{ subFeature.title }}
        .no-feature(v-if="!includeOptions.includes(subFeature.id)")
  tariff-column-header.footer(
    :title="title"
    :description="description"
    :description-hint="descriptionHint"
    :hit="hit"
    :hit-label="hitLabel"
    :price="price"
    :price-hint="priceHint"
    :promo-price-rule="promoPriceRule"
    :promo-for-new-users="promo?.newUsersOnly"
    @select-tariff="$emit('selectTariff')"
  )
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { costTariff } from "@/utils/formatter/costFormatter";
import { type PromoRuleI, usePromo } from "~/use/other/usePromo";

import UiButton from "@/components/ui/button/UiButton.vue";
import OldWarning from "@/components/ui/tooltip/OldWarning.vue";
import TariffColumnHeader from "~/components/pages/settings/tariffPage/TariffColumnHeader.vue";

export default defineComponent({
  name: "TariffColumn",
  components: {
    TariffColumnHeader,
    UiButton,
    OldWarning,
  },
  emits: [
    'selectTariff',
  ],
  props: {
    tariffId: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    baseCost: {
      type: Number,
      required: true,
    },
    discount: {
      type: Number,
      required: true,
    },
    period: {
      type: Number,
      required: true,
    },
    hit: {
      type: Boolean,
      default: false,
    },
    hitLabel: {
      type: String,
      default: '',
    },
    includeOptions: {
      type: Array,
      required: true,
    },
    descriptionHint: {
      type: String,
      required: true,
    },
    showPriceHint: {
      type: Boolean,
      default: false,
    },
    userCount: {
      type: String,
      default: '1 пользователь',
    },
    priceHint: {
      type: String,
      default: '',
    },
  },
  setup(props) {

    const { promo } = usePromo();

    const price = computed(() => props.period === 1 ? props.baseCost : Math.trunc((props.baseCost * props.discount * props.period)/100)*100)
    const promoPriceRule = computed<PromoRuleI | undefined>(() => promo.value?.rules[`${ props.tariffId }-${ props.period }`])

    const features = [
      {
        id: 1,
        title: "Поиск Закупок",
        subFeatures: [
          { id: 2, title: "Единая база закупок из нескольких тысяч источников по 44-ФЗ, 223-ФЗ, коммерческим площадкам" },
          { id: 3, title: "Возможность ручного поиска закупок" },
          { id: 4, title: "Автоматические шаблоны поиска закупок без ограничений по количеству шаблонов" },
          { id: 5, title: "Профессиональные шаблоны" },
          { id: 6, title: "Рассылка тендеров на e-mail" },
          { id: 7, title: "Экспертное выявление скрытых тендеров (СФО и ДВФО)" },
          { id: 8, title: "Экспертная фильтрация мусорных тендеров (СФО и ДВФО)" },
        ]
      },
      {
        id: 9,
        title: "Управление Тендерами",
        subFeatures: [
          { id: 10, title: props.userCount },
          { id: 11, title: "Управление жизненным циклом заявок и контрактов" },
          { id: 12, title: "Комментарии и тэги к любым закупкам" },
          { id: 13, title: "Выгрузка закупок в Excel" },
          { id: 14, title: "Разграничение доступа" },
          { id: 15, title: "Email-уведомления о регламентных сроках активных закупок" },
        ]
      },
      {
        id: 17,
        title: "Аналитика",
        subFeatures: [
          { id: 18, title: "Аналитика по поставщикам" },
          { id: 19, title: "Аналитика по заказчикам" },
          { id: 20, title: "Подробная аналитика по сводным отраслям, запросам, группам компаний" },
          { id: 21,title: "Выгрузка результатов аналитики в Excel" },
        ]
      },
    ]

    return {
      price,
      promo,
      promoPriceRule,
      features,
    };
  },
})
</script>

<style scoped lang="scss">
$tariff-border: 0.8px solid #42454b33;
$tariff-shadow: 0 11px 30px rgba(153, 159, 172, 0.2);

.tariff-column, header, footer {
  display: flex;
  flex-flow: column;
  gap: 16px;

  align-items: center;
  text-align: center;
}

header {
  padding: 32px 20px;
  border-bottom: $tariff-border;
}

.footer {
  border-top: $tariff-border;
}

.tariff-column {
  display: flex;
  flex-flow: column;
  gap: 16px;

  align-items: center;
  text-align: center;

  margin: 16px 0;
  padding: 0;
  border: $tariff-border;
  border-radius: 6px;
  background-color: var(--main-color-white);
  flex: 1;
  position: relative;

  &._hit {
    padding: 16px 0;
    margin: 0;
    box-shadow: $tariff-shadow;
  }
}

.tariff-column__content  {
  .tariff-column__feature-block-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    padding: 22px 16px;
  }

  .tariff-column__feature-item {
    font-size: 14px;
    line-height: 18px;
    padding: 8px 16px;
    color: #6e6e6e;
    position: relative;

    .hidden {
      visibility: hidden;
    }

    .no-feature {
      position: absolute;
      width: 10%;
      left: 45%;
      top: 50%;
      border-bottom: 1px solid #6e6e6e;
    }
  }
}
</style>
