import { computed, ref } from "vue";
import { useApi } from "@/use/api/useApi";
import { defineStore } from "pinia";
import { getDefaultCompany } from "@/components/pages/settings/companyManagement/useCompanyManagement";
import { parse } from "date-fns";
import { applyFullNameToList } from "@/utils/formatter/nameFormatter";

import type { PatchOrderExecutionI } from "@/use/tenderService/TenderServiceInterface";
import type { AdminI, CompanyItemI, ExtendedCompanyItemI, TariffInfoI } from "@/stores/auth/UserInterface";
import { AdminTabs } from "~/router/tabs";

function getTariff() : TariffInfoI {
  return {
    tariff: { id: 0, title: '' },
    dateEndTariff: '',
    tariffOptions: [],
    possibleUsersCount: 0,
  }
}

const useTmManagerStore = defineStore('tenmonManagerStore', () => {

  const api = useApi();

  const adminsList = ref<AdminI[]>([]);

  const adminsError = ref(false);
  const adminsLoading = ref(false);

  const activeAdmins = computed(() => adminsList.value.filter(m => m.isActual))

  /** selected client info */
  const selectedCompanyInfo = ref<ExtendedCompanyItemI|null>(null);

  const clientCompanyInfo = ref<CompanyItemI>(getDefaultCompany())
  const clientTariffInfo = ref<TariffInfoI>(getTariff())

  const isClientPlanExpired = ref<boolean>(false)

  const adminPageMode = ref(AdminTabs.Clients);

  async function getClientData() {
    return useApi().companies.fetchCompanyInfo(selectedCompanyInfo.value?.id)
      .then((data) => {
        clientTariffInfo.value = formatTariffData(data)
        clientCompanyInfo.value = formatCompanyData(data)
        isClientPlanExpired.value =
          clientTariffInfo.value?.dateEndTariff
            ? new Date() > parse(clientTariffInfo.value?.dateEndTariff + ' +0300', 'dd-MM-yyyy HH:mm x', new Date())
            : false;
      })
  }

  async function getAdminsList() {
    adminsLoading.value = true
    adminsError.value = false

    return useApi().admin.fetchAdminsList()
      .then((data) => adminsList.value = applyFullNameToList<AdminI>(data))
      .catch(() => adminsError.value = true)
      .finally(() => adminsLoading.value = false)
  }

  function formatTariffData(data: any) {
    return {
      tariff: data.tariff,
      tariffOptions: data.tariff?.id === 8 ? data.companyOptions.map(e => e?.option?.id) : [],
      dateEndTariff: data.dateEndTariff,
      possibleUsersCount: data.possibleUsersCount,
    }
  }

  function formatCompanyData(data: any) {
    return {
      id: data.id,
      title: data.title || '',
      inn: data.inn || '',
      kpp: data.kpp || '',
      ogrn: data.ogrn || '',
      fullTitle: data.fullTitle || '',
      factAddress: data.factAddress || '',
      legalAddress: data.legalAddress || '',
      postAddress: data.postAddress || '',
    }
  }

  function editTenderServiceOrder(order: PatchOrderExecutionI) {
    const payload = {
      order: `/api/orders/${order.id}`,
      executor: order.executor?.id ? `/api/admins/${order.executor.id}` : null,
      status: order.status ? `/api/orders-statuses/${order.status}` : null,
      comment: '',
    }
    return api.orders.postOrderExecution(payload);
  }

  function editOrderCost(order: PatchOrderExecutionI) {
    const payload = {
      cost: order.cost,
    }
    return api.orders.patchOrder(order.id, payload);
  }

  function resetClientData() {
    selectedCompanyInfo.value = null
    clientCompanyInfo.value = getDefaultCompany()
    clientTariffInfo.value = getTariff()
    isClientPlanExpired.value = false
  }

  function resetStore() {
    resetClientData();
    adminsList.value = [];
  }

  return {
    adminsList,
    adminsError,
    adminsLoading,
    activeAdmins,
    selectedCompanyInfo,
    clientCompanyInfo,
    clientTariffInfo,
    isClientPlanExpired,
    adminPageMode,
    getAdminsList,
    getClientData,
    formatCompanyData,
    editTenderServiceOrder,
    editOrderCost,
    resetStore,
    resetClientData,
  }

}, { persist: true })

export default useTmManagerStore;
