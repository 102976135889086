<template lang="pug">
.radio-group
  .radio-item(
    v-for="radio of options"
    :key="radio.id"
    :class="radio.disabled && 'disabled'"
  )
    input.radio-input(
      type="radio"
      :id="id + '-' + radio.id"
      :value="radio.id"
      :checked="modelValue === radio.id"
      :disabled="radio.disabled"
      @change="$emit('update:modelValue', radio.id)"
    )
    label(:for="id + '-' + radio.id")
      strong {{ radio.title }}
      span.message(v-if="radio.message") {{ radio.message }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

interface RadioI {
  id: string|number,
  title: string,
  message?: string,
  disabled?: boolean,
}

export default defineComponent({
  name: 'RadioGroup',
  props: {
    modelValue: {},
    options: {
      type: Array as PropType<RadioI[]>,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';

.radio-group {
  display: flex;
  flex-flow: column;
  gap: 12px;
}

.radio-input {
  margin: 4px 0;
}

.radio-item {
  display: flex;
  gap: 12px;
  align-items: start;
  cursor: pointer;
  color: var(--main-text-color);

  &.disabled {
    cursor: default;
    color: var(--disabled-text-color);
  }

  label {
    font-size: 14px;
    line-height: 20px;
    cursor: inherit;
    display: flex;
    flex-flow: column;
    gap: 8px;

    strong {
      font-weight: normal;
    }

    .message {
      @include label-13-16;
      color: var(--main-placeholder-color);
    }
  }
}
</style>
