<template lang="pug">
.price-setter
  .period
    ui-checkbox(
      v-model="checkbox"
      :id="`tariff-${baseCost}-${period}`"
      :label="`${ period } ${ period === 1 ? 'месяц' : 'месяцев' } - ${ costTariff().format(price) }`"
    )
  .price(v-if="active")
    cost-input(v-if="mode" v-model="cost.value")
    cost-input(v-if="!mode" v-model="cost.value" :min="0" :max="100" show-custom-count)
      template(#count) {{ discountPrice }}
    ui-radio-button.full-height(v-model="mode" :options="operatorOptions")
    icon-button(
      :size="16"
      :icon="UiIconNames.Icon_Close"
      tooltip-title="Отменить"
      @click="switchActive(!active)"
    )
  span.action(v-else @click="switchActive") + Указать правило
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { costTariff } from "~/utils/formatter/costFormatter";
import { useVModel } from "@vueuse/core";

import UiCheckbox from "~/components/ui/checkbox/UiCheckbox.vue";
import IconButton from "~/components/ui/button/IconButton.vue";
import CostInput from "~/components/ui/form/input/CostInput.vue";
import UiRadioButton from "~/components/ui/radio/UiRadioButton.vue";
import BaseInput from "~/components/ui/base/BaseInput.vue";

import UiIconNames from "@/components/ui/icon/UiIconNames";
import ValidationRow from "~/components/ui/form/input/ValidationRow.vue";

export default defineComponent({
  name: "ManualPriceSetter",
  components: {
    ValidationRow,
    BaseInput,
    UiRadioButton,
    CostInput,
    IconButton,
    UiCheckbox,
  },
  methods: {
    costTariff,
  },
  emits: [
    'update:modelValue',
  ],
  props: {
    modelValue: {},
    period: {
      type: Number,
      default: 1,
    },
    price: {
      type: Number,
      default: 0,
    },
    baseCost: {
      type: Number,
      default: 0,
    },
    selectedRules: {
      type: Array as PropType<string[]>,
      default: () => ([]),
    },
    ruleKey: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {

    const active = ref(!!props.modelValue);

    const cost = useVModel(props, 'modelValue', context.emit);
    watch(cost, () => {
      active.value = !!props.modelValue;
    });

    const checkboxesList = useVModel(props, 'selectedRules', context.emit);

    const mode = computed({
      get: () => cost.value?.isRubles,
      set(m: boolean) {
        cost.value.isRubles = m
        if (m) cost.value.value = discountPrice.value
        else cost.value.value = 0
      }
    })

    const checkbox = computed({
      get: () => checkboxesList.value.includes(props.ruleKey),
      set(value: boolean): void {
        if (value) checkboxesList.value.push(props.ruleKey);
        else checkboxesList.value = checkboxesList.value.filter(e => e !== props.ruleKey)
      },
    })

    const operatorOptions = [
      { id: 'rubles', label: '₽', value: true },
      { id: 'percent', label: '%', value: false },
    ]

    const discountPrice = computed(() => costTariff().format(
        (cost.value?.isRubles || !cost.value?.value || Number(cost.value?.value) < 0 || Number(cost.value?.value) > 100) ? props.price : Math.trunc((props.price * (1 - (Number(cost.value?.value)/100)))/100)*100
    ))

    function switchActive(v: boolean) {
      if (!v) cost.value = null
      else if (!cost.value) cost.value = {
        value: 0,
        isRubles: false,
      }

      active.value = v;
    }

    return {
      checkbox,
      discountPrice,
      active,
      cost,
      mode,
      operatorOptions,
      switchActive,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
.price-setter {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 8px;
  font-size: 14px;
  min-height: 32px;
  align-items: center;

  .period {
    display: flex;
    flex-flow: row;
    gap: 8px;
  }

  .action {
    color: var(--default-blue-color);
    font-weight: 600;
    cursor: pointer;
    width: fit-content;
  }

  .price {
    display: flex;
    flex-flow: row;
    gap: 8px;
  }

  .full-height {
    height: 32px;
    box-sizing: border-box;
  }
}
</style>
