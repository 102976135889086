<template lang="pug">
.campaign-list
  alert-message(v-if="!isSuperAdmin" type="default" label="Редактирование информации из данного раздела доступно только менеджерам с ролью \"ТМ Администратор\"")
  settings-panel
    template(#header) {{ pageTitle }}
    template(#headerActions v-if="isSuperAdmin && !editableCampaign")
      .link(@click="createPromo") Добавить новую кампанию

    .promo-table(v-show="!editableCampaign")
      ui-table(
        :data="data"
        :loader="isFetching"
        :columns="columns"
        :has-error="error"
        :header-sticky="true"
        v-model="preFiltrationForm"
        @apply="jumpToPage(1)"
      )
        template(#status="{ rowData }")
          ui-tag(:name="rowData.isActual ? 'Активно' : 'В архиве'" size="small" :type="rowData.isActual ? 'default' : 'info'")

        template(#actions="{ rowData }")
          .flex-row
            icon-button(
              :size="16"
              :icon="rowData.isActual ? UiIconNames.Icon_Unlock : UiIconNames.Icon_Lock"
              :tooltip-title="rowData.isActual ? 'Архивировать' : 'Разархивировать'"
              @click="editCampaignStatus(rowData)"
            )
            icon-button(
              :size="16"
              :icon="UiIconNames.Icon_Next"
              tooltip-title="Редактировать кампанию"
              @click="editCampaign(rowData)"
            )

      ui-pagination(
        :count-results="data.length"
        :total-results="totalResults"
        :current-page="preFiltrationForm.page"
        :total="totalPages"
        @update:current-page="jumpToPage($event, false)"
      )

    .promo-editor(v-if="editableCampaign")
      .go-back(@click="goBack")
        ui-icon(clickable :icon="UiIconNames.Icon_Back")
        span Вернуться назад
      campaign-editor(:data="editableCampaign" :is-create="isCreateMode" @success="onDataChange")

campaign-status-dialog(
  v-if="showBlockDialog"
  v-model:show="showBlockDialog"
  :campaign="blockCampaign"
  :mode="blockCampaign?.isActual ? 'lock' : 'unlock'"
  @success="updateCampaignInfo"
)
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useApi } from "~/use/api/useApi";
import { formatDateTime } from "~/utils/formatter/dateFormatter";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import SettingsPanel from "~/components/pages/settings/common/SettingsPanel.vue";
import UiTable from "~/components/ui/table/UiTable.vue";
import UiPagination from "~/components/ui/pagination/UiPagination.vue";
import IconButton from "~/components/ui/button/IconButton.vue";
import UiIcon from "~/components/ui/icon/UiIcon.vue";
import UiButton from "~/components/ui/button/UiButton.vue";
import UiTag from "~/components/ui/tag/UiTag.vue";
import CampaignEditor from "~/components/pages/admin/campaign/CampaignEditor.vue";
import CampaignStatusDialog from "~/components/pages/admin/campaign/CampaignStatusDialog.vue";
import AlertMessage from "~/components/ui/alert/AlertMessage.vue";

import UiIconNames from "~/components/ui/icon/UiIconNames";
import type { CampaignI } from "~/components/pages/admin/campaign/CampaignInterface";

export default defineComponent({
  name: "CampaignList",
  components: {
    AlertMessage,
    CampaignStatusDialog,
    CampaignEditor,
    UiTag,
    UiButton,
    UiIcon,
    IconButton,
    UiTable,
    UiPagination,
    SettingsPanel,
  },
  methods: {
    formatDateTime,
  },
  setup() {

    const data = ref<CampaignI[]>([]);
    const error = ref<boolean>(false);

    const isFetching = ref<boolean>(true);

    const totalPages = ref<number>(1);
    const totalResults = ref<number>(0);

    const editableCampaign = ref<CampaignI|undefined>();
    const isCreateMode = ref<boolean>(false);

    const blockCampaign = ref<CampaignI|undefined>();
    const showBlockDialog = ref<boolean>(false);

    const preFiltrationForm = ref({
      page: 1,
    });

    const columns = computed(() => ({
      uid: { size: 180, headerLabel: 'Идентификатор' },
      title: { size: isSuperAdmin.value ? 622 : 694, bold: true, headerLabel: 'Название рекламной кампании' },
      status: { size: 200, headerLabel: 'Статус' },
      actions: { size: 72, invisible: !isSuperAdmin.value },
    }))

    const pageTitle = computed(() => {
      if (editableCampaign.value) return isCreateMode.value ? 'Создание рекламной кампании' : 'Редактирование рекламной кампании'
      return 'Список рекламных кампаний'
    })

    const { isSuperAdmin } = useUserAccess();

    /** получение списка всех рекламных кампаний */
    useApi().campaigns.fetchCampaignsList<any[]>(preFiltrationForm.value)
      .then((hydraData: any) => {
        totalResults.value = Number(hydraData['totalItems'])
        totalPages.value = Math.ceil(hydraData['totalItems'] === 0 ? 1 : (hydraData['totalItems'] / 30))
        data.value = hydraData['member']
      })
      .catch(() => error.value = true)
      .finally(() => isFetching.value = false)

    function jumpToPage(p: number, resetPages = true) {
      preFiltrationForm.value.page = p
      applyFilters(resetPages)
    }

    /** фильтрация рекламных кампаний */
    function applyFilters(resetPages: boolean) {
      isFetching.value = true;

      if (resetPages) {
        totalResults.value = 0
        totalPages.value = 1
      }

      data.value = []
      error.value = false

      useApi().campaigns.fetchCampaignsList<any[]>(preFiltrationForm.value)
        .then((filtersData: any) => {
          totalResults.value = Number(filtersData['totalItems'])
          totalPages.value = Math.ceil(filtersData['totalItems'] === 0 ? 1 : (filtersData['totalItems'] / 30))
          data.value = filtersData['member']
        })
        .catch(() => error.value = true)
        .finally(() => isFetching.value = false)
    }

    function updateCampaignInfo(p: CampaignI) {
      const index = data.value.findIndex(t => t.id === p.id);
      if (index !== -1) data.value[index] = p
    }

    function onDataChange(p: CampaignI) {
      if (isCreateMode.value) data.value.push(p)
      else updateCampaignInfo(p)

      goBack()
    }

    /** переход к редактированию акции */
    function editCampaign(p: CampaignI) {
      if (!p.id) return;

      isCreateMode.value = false;
      editableCampaign.value = p;
    }

    function editCampaignStatus(p: CampaignI) {
      blockCampaign.value = p;
      showBlockDialog.value = true;
    }

    function createPromo() {
      isCreateMode.value = true;
      editableCampaign.value = {
        id: undefined,
        uid: '',
        title: '',
        isActual: true,
      };
    }

    /** выход из редактирования акции */
    function goBack() {
      editableCampaign.value = undefined;
    }

    return {
      data,
      error,
      isFetching,
      totalPages,
      totalResults,
      preFiltrationForm,
      showBlockDialog,
      blockCampaign,
      editableCampaign,
      isCreateMode,
      isSuperAdmin,
      pageTitle,
      columns,
      UiIconNames,
      editCampaignStatus,
      goBack,
      editCampaign,
      createPromo,
      jumpToPage,
      applyFilters,
      updateCampaignInfo,
      onDataChange,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/links";

.link {
  @include link-mixin;

  &:hover {
    color: var(--main-color-blue-dark);
  }
}

.campaign-list {
  display: flex;
  flex-flow: column;
  gap: 24px;
}

.promo-table,
.promo-editor {
  display: flex;
  flex-flow: column;
  gap: 24px;
}

.go-back {
  display: flex;
  flex-flow: row;
  gap: 16px;

  font-size: 14px;
  line-height: 20px;
  font-weight: bold;

  align-items: center;
  cursor: pointer;
  color: var(--default-blue-color);
  width: fit-content;
}

.loader {
  align-self: center;
}

.flex-row {
  display: flex;
  flex-flow: row;
  gap: 0;
}
</style>
