<template lang="pug">
filter-template.number-filter(
  :show-sorting="false"
  @save="applyFilters"
  @cancel="cancel"
)
  template(#content)
    .filter
      cleanable-title(from-filter-panel @reset="cleanFilterByName('govRuIds')") Номер закупки
      lot-number-input(:model-value="filters.govRuIds[0] || ''" @update:model-value="filters.govRuIds[0] = $event")
    .filter(v-if="!isSearch")
      cleanable-title(from-filter-panel @reset="cleanFilterByName('customerInns')") ИНН заказчика
      lot-number-input(:model-value="filters.customerInns[0] || ''" @update:model-value="filters.customerInns[0] = $event" placeholder="Введите ИНН заказчика")
    .filter(v-if="!isSearch")
      cleanable-title(from-filter-panel @reset="cleanResponsible") Ответственный
      manager-selector(
        :model-value="filters.responsiblePersonIds[0]"
        :teleported="false"
        @update:model-value="filters.responsiblePersonIds = $event ? [$event] : []"
      )
      ui-checkbox.margin(v-model="filters.withoutResponsible" id="apps-without-responsible" label="Показать лоты, на которые никто не назначен")
    .filter
      cleanable-title(from-filter-panel @reset="cleanFilterByName('lotsSearch')") Ключевые слова
      tag-input(
        writable
        v-model="filters.lotsSearch.keywords"
        placeholder="Введите ключевые слова и места поставки"
      )
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { PreFiltrationFormI } from "@/utils/getters/defaultFilters";

import { defineComponent, toRefs, computed } from 'vue'
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";

import LotNumberInput from "@/components/ui/form/input/NumberAsTextInput.vue";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import TagInput from "@/components/tagInput/TagInput.vue";
import ManagerSelector from "~/components/selector/ManagerSelector.vue";
import UiCheckbox from "~/components/ui/checkbox/UiCheckbox.vue";
import useSearchStore from "~/stores/search/useSearchStore";
import { Tabs } from "~/router/tabs";

export default defineComponent({
  name: "LotInfoFilter",
  components: {
    UiCheckbox,
    ManagerSelector,
    TagInput,
    CleanableTitle,
    LotNumberInput,
    FilterTemplate,
  },
  emits: [
    'close',
    'apply',
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormI>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {

    const { modelValue } = toRefs(props)

    const route = useRoute();

    const searchStore = useSearchStore();
    const { tags } = storeToRefs(searchStore);

    const isSearch = computed(() => route.name === Tabs.Search)

    const {
      filters,
      cancel,
      cleanFilterByName,
      applyFilters,
    } = useFiltersComponent<PreFiltrationFormI>(modelValue, false, ['govRuIds', 'customerInns', 'lotsSearch', 'responsiblePersonIds', 'withoutResponsible'], emit, route.name)

    function cleanResponsible() {
      cleanFilterByName('responsiblePersonIds')
      cleanFilterByName('withoutResponsible')
    }

    return {
      tags,
      isSearch,
      filters,
      cancel,
      cleanFilterByName,
      applyFilters,
      cleanResponsible,
    }
  }
})
</script>

<style scoped lang="scss">
.number-filter {
  width: 420px;

  :deep(.filter-title__label) {
    font-weight: bold;
    text-transform: unset;
  }

  :deep(#tag-editor) {
    height: 100px !important;
    border: 1px solid var(--input-border-color);
  }
}

.margin {
  margin-top: 4px;
}
</style>
