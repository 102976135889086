<template lang="pug">
main-section
system-capabilities-intro
system-capabilities
about-search
about-analytic
about-notifications
#tariffs.landing-page__block
  tariff-selector
yandex-map
call-for-registration(mini)
support-block
</template>

<script setup lang="ts">
import { scrollIntoView } from "@/utils/scroller/documentScroller";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";

import MainSection from "@/components/pages/landing/MainSection.vue";
import SystemCapabilitiesIntro from "@/components/pages/landing/SystemCapabilitiesIntro.vue";
import SystemCapabilities from "@/components/pages/landing/SystemCapabilities.vue";
import TariffSelector from "@/components/pages/settings/tariffPage/TariffSelector.vue";
import YandexMap from "@/components/pages/landing/YandexMap.vue";
import SupportBlock from "@/components/pages/landing/SupportBlock.vue";
import AboutAnalytic from "@/components/pages/landing/AboutAnalytic.vue";
import AboutNotifications from "@/components/pages/landing/AboutNotifications.vue";
import AboutSearch from "@/components/pages/landing/AboutSearch.vue";
import CallForRegistration from "~/components/pages/landing/CallForRegistration.vue";

definePageMeta({
  name: 'LandingPage',
  // layout: 'dashboard',
});

const { storage } = useServiceStorage();

setTimeout(() => {
  nextTick(() => {
    if (storage.scrollIntoAnalytic) {
      scrollIntoView('analytics', 'start')
      storage.scrollIntoAnalytic = false
    }
    if (storage.scrollIntoTariff) {
      scrollIntoView('tariffs', 'start')
      storage.scrollIntoTariff = false
    }
    if (storage.scrollIntoContacts) {
      scrollIntoView('contacts', 'start')
      storage.scrollIntoContacts = false
    }
  })
}, 1000);
</script>

<style scoped lang="scss">
@import "@/assets/styles/landing/landing";

#tariffs {
  scroll-margin-top: 60px;

  :deep(.settings-title) {
    @include landing-title;
  }
}

#contacts {
  scroll-margin-top: 100px;
}
</style>
