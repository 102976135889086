<template lang="pug">
el-container#app-container
  el-header.app-header
    main-header
  el-container.app-main-container
    el-aside(v-if="!isBlocked && !isPlanExpired")
      aside-menu
    el-main#app-main
      ui-loader.app-main-loader(v-if="showLoader" :label="isTmManager ? 'Загружаем данные клиента' : 'Инициализация системы'")
      div.app-main-content(v-else)
        slot
      .app-footer(:class="!isBlocked && !isPlanExpired && 'mini'")
        main-footer.app-main-footer(:mini="!isBlocked && !isPlanExpired")

icon-scroll-page-back
page-loader(:show="excelDownloadLoader" :file-name="excelFileName")
update-app
tags-editor-dialog
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";
import { resetAllStoresData } from "@/utils/authorization/auth";
import { useRoute } from "vue-router";
import { useExcelDownloader } from "@/use/other/useExcelDownloader";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";

import MainHeader from "@/components/header/MainHeader.vue";
import MainFooter from "@/components/footer/MainFooter.vue";
import IconScrollPageBack from "@/components/other/IconScrollPageBack.vue";
import AsideMenu from "@/components/mainMenu/NavMenu.vue";
import UiLoader from "@/components/ui/loader/UiLoader.vue";
import PageLoader from "@/components/other/PageLoader.vue";
import UpdateApp from "@/components/other/UpdateApp.vue";
import TagsEditorDialog from "~/globals/tags/TagsEditorDialog.vue";

import { Tabs } from "@/router/tabs";
import { sendMetrica, YmGoals } from "~/utils/logger/metrica";

import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";
import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";
import useManualsStore from "@/stores/manuals/useManualsStore";
import useTemplatesStore from "@/stores/templates/useTemplatesStore";
import useUsersStore from "@/stores/users/useUsersStore";
import useSearchStore from "@/stores/search/useSearchStore";
import useContractsStore from "@/stores/contracts/useContractsStore";
import useApplicationsStore from "@/stores/apps/useApplicationsStore";
import useAnalyticsSuppliersStore from "@/stores/analytics/useAnalyticsSuppliersStore";
import useAnalyticsCustomersStore from "@/stores/analytics/useAnalyticsCustomersStore";
import useAnalyticsIndustryStore from "@/stores/analytics/useAnalyticsIndustryStore";

export default defineComponent({
  name: "default",
  components: {
    TagsEditorDialog,
    PageLoader,
    UiLoader,
    MainHeader,
    AsideMenu,
    MainFooter,
    IconScrollPageBack,
    UpdateApp,
  },
  setup() {

    const route = useRoute();
    const globalLoader = ref(false);

    const {
      excelDownloadLoader,
      excelFileName,
    } = useExcelDownloader();

    const manualsStore = useManualsStore();
    const templatesStore = useTemplatesStore();
    const { getAllTemplates } = templatesStore;

    const searchStore = useSearchStore();
    const contractsStore = useContractsStore();
    const applicationStore = useApplicationsStore();
    const interfaceStore = useInterfaceSettingsStore();

    const {
      isTmManager,
      isBlocked,
      isPlanExpired,
      isTrial,
      enableTemplates,
      enableLotManagement,
    } = useUserAccess();

    const tabs = [Tabs.Search, ...Object.values(Tabs.Applications), ...Object.values(Tabs.Contracts), Tabs.Analytics.Industry]
    const showLoader = computed(() => isTmManager.value ? globalLoader.value : globalLoader.value && tabs.includes(route.name))

    const { storage } = useServiceStorage();
    const { getQuery, getQueryDot, getDotQueryActual } = useSuperAccount();

    const { getUsers } = useUsersStore();
    const { getInterfaceSettings } = interfaceStore;

    const tenmonManagerStore = useTmManagerStore();
    const { selectedCompanyInfo } = storeToRefs(tenmonManagerStore);
    const {
      getAdminsList,
      getClientData,
    } = tenmonManagerStore;

    function initManualsData() {
      nextTick(() => {
        setTimeout(() => {
          if (!isBlocked.value) {
            manualsStore.manuals.getRegions()
            manualsStore.manuals.getIndustries()
            manualsStore.manuals.getCurrencies()
            if (enableLotManagement.value) manualsStore.manuals.getAppStatuses()
            if (enableLotManagement.value) manualsStore.manuals.getContractStatuses()
            manualsStore.manuals.getServiceStatuses()
            manualsStore.manuals.getMailingSchedule()
            manualsStore.manuals.getObjectTypes()
            manualsStore.manuals.getSiteGroups()
            manualsStore.manuals.getLaws()
          }
        }, 1000)
      })
    }

    function initUser(query: string) {
      return Promise
        .all([
          getUsers(getQueryDot()),
          searchStore.getTenderTags(getDotQueryActual()),
          enableTemplates.value ? getAllTemplates() : undefined,
        ]);
    }

    function initClientAccount(query: string) {
      return Promise
        .all([
          getUsers(getQueryDot()),
          searchStore.getTenderTags(getDotQueryActual()),
          getAllTemplates(),
          getClientData(),
        ]);
    }

    function initTmManager() {
      return Promise
        .all([
          getAdminsList(),
        ]);
    }

    function loadClient(client: any) {
      if (client) {
        globalLoader.value = true;
        resetAllStoresData()
        initClientAccount(getQuery()).then(() => {
          globalLoader.value = false;
        })
      } else {
        resetAllStoresData()
      }
    }

    function onInit() {
      const q = getQuery();

      if (isTmManager.value) {
        initTmManager()
      } else {
        if (storage.onLogin) globalLoader.value = true;
        getInterfaceSettings();
        initUser(q).finally(() => {
          if (storage.onLogin) {
            if (templatesStore.templatesList.length) {
              searchStore.searchPageMode = 'templateMode'
              searchStore.selectedTemplatesIds = [...templatesStore.templatesList.map(e => e.id), -2]
            }
            storage.onLogin = false
          }
          globalLoader.value = false
        })
      }

      initManualsData()
    }

    if (!isBlocked.value) onInit()

    if (isTmManager.value) {
      if (selectedCompanyInfo.value) loadClient(selectedCompanyInfo.value)
      watch(selectedCompanyInfo, (v) => loadClient(v))
    }

    if (storage.onReload) {
      storage.onReload = false
      searchStore.resetFastSearchData()
      if (!interfaceStore.functions.saveFiltersOnReload) {
        searchStore.resetSearchStore(false)
        applicationStore.resetApplicationStore()
        contractsStore.resetContractsStore()
      }
      if (!interfaceStore.functions.saveAnalyticsOnReload) {
        useAnalyticsSuppliersStore().resetStore();
        useAnalyticsCustomersStore().resetStore();
        useAnalyticsIndustryStore().resetStore();
      }
      if (isTrial.value) sendMetrica(YmGoals.PageReload)
    }

    return {
      isTmManager,
      isPlanExpired,
      isBlocked,
      showLoader,
      globalLoader,
      excelDownloadLoader,
      excelFileName,
      Tabs,
    };
  },
});
</script>

<style scoped lang="scss">
#app-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .app-header {
    --el-header-height: var(--main-header-height);
    z-index: 5000;
    box-shadow: 0 5px 10px rgba(188, 186, 190, 0.2);
    padding: 0;
    background-color: white;
  }

  .app-main-container {
    overflow: auto;
    background-color: #FAFAFA;
  }

  ::v-deep(.el-aside) {
    width: auto;
    position: sticky;
    top: 0;
    overflow: visible;
    z-index: 4999;
    background-color: white;
  }

  #app-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    align-items: center;
    gap: 32px;

    .app-main-loader {
      height: 100%;
    }

    .app-main-content {
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 100%;
      max-width: var(--max-content-width);
    }

    .app-footer {
      display: flex;
      width: 100%;
      justify-content: center;
      //background-color: #f8fafc;
      background-color: var(--main-color-white);
      box-sizing: border-box;
      padding: 24px;

      &.mini {
        padding: 0;
        //background-color: var(--main-color-white);
        background-color: #FAFAFA;
      }
    }

    .app-main-footer {
      display: flex;
      padding: 0 24px;
      max-width: var(--max-content-width);
      box-sizing: border-box;
      width: 100%;
    }
  }
}
</style>
