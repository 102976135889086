<template lang="pug">
base-select(
  ref="baseSelect"
  :disabled="disabled"
  :is-selected="selectedValue.length > 0"
  :is-empty="!(options && options.length > 0)"
  :placeholder="placeholder"
  :icon-right="iconRight"
  :loading="loading"
  :teleported="teleported"
  :no-data-description="noDataDescription"
  @search="$emit('search', $event)"
  @click:icon="$emit('click:icon', $event)"
  @click:clear="$emit('click:clear')"
  @reset:options="$emit('reset:options')"
)
  template(#select-row)
    slot(name="select-row")
  template(#selector)
    .ui-multiselect__option(v-if="showSelectAll && count <= options.length")
      input(id="select-all" v-model="allSelected" type="checkbox" @change="selectAll()")
      label(for="select-all") Выбрать все

    .ui-multiselect__option.count(v-if="showCount && count > options.length") {{ getCountResults(options.length, count) }} Пожалуйста, уточните запрос.
    template(v-for="option in options" :key="option[trackBy]" @click.stop)
      .ui-multiselect__option(v-if="showCheckboxes")
        input(
          v-model="selectedValue"
          type="checkbox"
          :id="'option_' + option[trackBy]"
          :value="option"
          @change="$emit('on:update')"
        )
        label(:for="'option_' + option[trackBy]")
          slot(name="label" :option="option") {{ option[label] }}

      .ui-multiselect__option(v-else @click="$emit('on:select', option)")
        slot(name="label" :option="option") {{ option[label] }}
        slot(name="dot" :option="option")
          .dot(v-if="selectedValue.find(e => e[trackBy] === option[trackBy])")
</template>

<script lang="ts">
import { defineComponent, ref, watch, toRefs } from "vue";
import { useVModel } from "@vueuse/core";
import { getCountResults } from "@/utils/formatter/wordDeclension";
import { selectAllRef } from "@/utils/searchForm/searchFormWorker";

import BaseSelect from "@/components/ui/select/multiselect/BaseSelect.vue";

export default defineComponent({
  name: "UiMultiselect",
  methods: {
    getCountResults,
  },
  components: {
    BaseSelect,
  },
  emits: [
    "search",
    "on:update",
    "click:icon",
    "click:clear",
    "reset:options",
    "update:modelValue",
  ],
  props: {
    options: {
      type: Array,
      default: () => ([]),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      default: () => ([]),
    },
    /**
     * иконка справа со своей логикой при клике
     */
    iconRight: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showSelectAll: {
      type: Boolean,
      default: true,
    },
    showCheckboxes: {
      type: Boolean,
      default: true,
    },
    placeholder: String,
    /**
     * отслеживаемое поле
     */
    trackBy: {
      type: String,
      default: "id",
    },
    label: {
      type: String,
      default: "title",
    },
    /**
     * Кол-во результатов (именно всего найденных, а не тех, что выводятся options.length)
     */
    count: {
      type: Number,
      default: 0,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    /**
     * подпись под "Список пуст"
     */
    noDataDescription: {
      type: String,
      default: '',
    },
    teleported: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const baseSelect = ref();
    const { options } = toRefs(props);

    const allSelected = ref(false);
    const selectedValue = useVModel(props, 'modelValue', context.emit)

    function clearSearch() {
      baseSelect.value!.resetSearchText();
    }

    function selectAll() {
      selectAllRef(allSelected.value, selectedValue, options.value)
      context.emit('on:update')
    }

    /**
     * Установка значения "Выбрать все"
     * При смене опций/выбранных вариантов проверять, не все ли опции стали выбранными
     */
    watch([options, selectedValue], () => {
      allSelected.value = options.value?.map((e: any) => e.id).every(elem => selectedValue.value?.map((e: any) => e.id).includes(elem))
    })

    return {
      baseSelect,
      selectedValue,
      allSelected,
      clearSearch,
      selectAll,
    };
  },
});
</script>

<style scoped lang="scss">
.ui-multiselect__option {
  padding: 8px 16px;
  display: flex;
  flex-flow: row;

  &.count {
    font-size: 12px;
    font-weight: bold;
  }

  label {
    width: 100%;
    cursor: pointer;
  }

  &:hover:not(.count) {
    background-color: var(--hover-color);
    cursor: pointer;
  }
}

.dot {
  background: var(--default-blue-color);
  width: 6px;
  height: 6px;
  margin: 6px 6px 6px auto;
  border-radius: 50%;
  flex-shrink: 0;
}
</style>
