export const Tabs = {
  Landing: "pageLogin",
  Search: "search",
  Applications: {
    Main: "application",
    Calculation: "calculation",
    Participation: "participation",
    Bargaining: "bargaining",
  },
  Contracts: {
    Main: "contracts",
    Conclusion: "contractConclusion",
    Execution: "contractExecution",
    Completed: "contractCompleted",
  },
  Analytics: {
    Main: "analytics",
    Industry: "analyticsIndustry",
    Suppliers: "analyticsSupplier",
    Customers: "analyticsCustomer",
  },
  Services: "services",
  Training: {
    Main: "training",
    Comments: "supportComments",
    Templates: "supportTemplates",
    Filters: "supportFilters",
    Apps: "supportApps",
    Contracts: "supportContracts",
    Tags: "supportTags",
    MultiCardActions: "supportMultiCardActions",
    TenderServices: "supportTenderServices",
    NotificationHistory: "supportNotificationHistory",
    IndustryAnalytics: "supportIndustryAnalytics",
    CompanyAnalytics: "supportCompanyAnalytics",
    AccountManagement: "supportAccountManagement",
    UserManagement: "supportUsers",
    ChangePassword: "supportPassword",
    WatchedLots: "supportLots",
  },
  Settings: {
    Main: "settings",
    Notifications: "notifications",
    Account: "account",
    CompanyManagement: "companyManagement",
    SystemManagement: "systemManagement",
    UsersManagement: "usersManagement",
    Tariffs: "tariffs",
    InterfaceSettings: "interfaceSettings",
    TagsSettings: "tagsSettings",
  },
  NotificationHistory: "historyNotifications",
  ReleaseNotesFront: "releaseNotesFront",
  ReleaseNotesBack: "releaseNotesBack",
  LotDetail: "searchDetail",
  LotGovDetail: "searchGovDetail",
  PageNotFound: "pageNotFound",
  PageNotAllowed: "pageNotAllowed",
  PageExpired: "pageExpired",
  PageBlocked: "pageBlocked",
  AdminPages: {
    AdminPage: "adminPage",
    PageTariffChanger: "pageTariffChanger",
  }
};

// подстраницы в панели администратора
export const AdminTabs = {
  Clients: "admin-companies",
  Managers: "admin-managers",
  Campaigns: "admin-campaigns",
  Promos: "admin-promo",
}

export const TrainingArticles = [
  {
    name: 'Поиск по ключевым словам и расширенные фильтры поиска',
    tabTitle: 'Обучение: Поиск по ключевым словам и расширенные фильтры поиска',
    time: '5-7 минут',
    sections: ["Поиск"],
    key: Tabs.Training.Filters,
    related: [Tabs.Training.Templates],
  },
  {
    name: 'Создание и работа с шаблонами',
    tabTitle: 'Обучение: Создание и работа с шаблонами',
    time: '3-5 минут',
    sections: ["Поиск"],
    key: Tabs.Training.Templates,
    related: [],
  },
  {
    name: 'Отметка лотов просмотренными',
    tabTitle: 'Обучение: Отметка лотов просмотренными',
    time: '2-3 минуты',
    sections: ["Поиск"],
    key: Tabs.Training.WatchedLots,
    related: [],
  },
  {
    name: 'Комментирование закупок',
    tabTitle: 'Обучение: Комментирование закупок',
    time: '1-2 минуты',
    sections: ["Поиск", "Заявки", "Контракты"],
    key: Tabs.Training.Comments,
    related: [Tabs.Training.Apps, Tabs.Training.Contracts],
  },
  {
    name: 'Работа с заявками',
    tabTitle: 'Обучение: Работа с заявками',
    time: '2-3 минуты',
    sections: ["Заявки"],
    key: Tabs.Training.Apps,
    related: [Tabs.Training.Contracts],
  },
  {
    name: 'Работа с контрактами',
    tabTitle: 'Обучение: Работа с контрактами',
    time: '5-6 минут',
    sections: ["Контракты"],
    key: Tabs.Training.Contracts,
    related: [Tabs.Training.Apps],
  },
  {
    name: 'Работа с тегами',
    tabTitle: 'Обучение: Работа с тегами',
    time: '5-6 минут',
    sections: ["Поиск", "Заявки", "Контракты"],
    key: Tabs.Training.Tags,
    related: [Tabs.Training.MultiCardActions],
  },
  {
    name: 'Множественный выбор лотов для назначения статуса/тега',
    tabTitle: 'Обучение: Множественный выбор лотов',
    time: '5-6 минут',
    sections: ["Поиск"],
    key: Tabs.Training.MultiCardActions,
  },
  {
    name: 'Тендерные услуги',
    tabTitle: 'Обучение: Тендерные услуги',
    time: '2-3 минуты',
    sections: ["Услуги"],
    key: Tabs.Training.TenderServices,
    related: [],
  },
  {
    name: 'История уведомлений',
    tabTitle: 'Обучение: История уведомлений',
    time: '2-3 минуты',
    sections: ["Уведомления"],
    key: Tabs.Training.NotificationHistory,
    related: [],
  },
  {
    name: 'Аналитика по отрасли',
    tabTitle: 'Обучение: Аналитика по отрасли',
    time: '2-3 минуты',
    sections: ["Аналитика"],
    key: Tabs.Training.IndustryAnalytics,
    related: [Tabs.Training.CompanyAnalytics],
  },
  {
    name: 'Аналитика по поставщикам и заказчикам',
    tabTitle: 'Обучение: Аналитика по поставщикам и заказчикам',
    time: '2-3 минуты',
    sections: ["Аналитика"],
    key: Tabs.Training.CompanyAnalytics,
    related: [Tabs.Training.IndustryAnalytics],
  },
  {
    name: 'Управление аккаунтом. Инструкция по работе с настройками системы',
    tabTitle: 'Обучение: Управление аккаунтом',
    time: '4-6 минут',
    sections: ["Настройки"],
    key: Tabs.Training.AccountManagement,
    related: [Tabs.Training.ChangePassword, Tabs.Training.UserManagement],
  },
  {
    name: 'Изменение логина и пароля',
    tabTitle: 'Обучение: Изменение логина и пароля',
    time: '2-3 минуты',
    sections: ["Настройки"],
    key: Tabs.Training.ChangePassword,
    related: [Tabs.Training.AccountManagement, Tabs.Training.UserManagement],
  },
  {
    name: 'Многопользовательский доступ',
    tabTitle: 'Обучение: Многопользовательский доступ',
    time: '3-5 минут',
    sections: ["Настройки"],
    key: Tabs.Training.UserManagement,
    related: [Tabs.Training.AccountManagement, Tabs.Training.ChangePassword],
  },
]

export type ApplicationsTab = "application" | "calculation" | "participation" | "bargaining";
export type ContractsTab = "contracts" | "contractConclusion" | "contractExecution" | "contractCompleted";
