<template lang="pug">
span(v-if="isAuth || show")
  slot {{ label }}
register-tooltip(v-else label="Полная информация доступна в личном кабинете")
  span.blurred-text {{ label?.toString()?.replace(/[^ ]/g, '░') }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import UiButton from "~/components/ui/button/UiButton.vue";
import RegisterTooltip from "~/components/other/RegisterTooltip.vue";

export default defineComponent({
  name: "BlurredSpan",
  components: {
    UiButton,
    RegisterTooltip,
  },
  props: {
    label: {
      type: [String, Number],
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    const { isAuth } = useUserAccess();

    return {
      isAuth,
    }
  }
})
</script>

<style scoped lang="scss">
.blurred-text {
  width: fit-content;
  cursor: pointer;
  line-break: anywhere;
}
</style>
