<template lang="pug">
settings-panel
  template(#header) {{ pageTitle }}
  template(#headerActions v-if="!editablePromo")
    .link(@click="createPromo") Добавить новую акцию

  .promo-table(v-show="!editablePromo")
    ui-table(
      :data="data"
      :loader="isFetching"
      :columns="columns"
      :has-error="error"
      :header-sticky="true"
      v-model="preFiltrationForm"
      @apply="jumpToPage(1)"
    )
      template(#dateStart="{ rowData }") {{ rowData?.dateStart ? formatDateTime(rowData?.dateStart) : 'Нет данных' }}
      template(#dateFinish="{ rowData }") {{ rowData?.dateFinish ? formatDateTime(rowData?.dateFinish) : 'Нет данных' }}
      template(#serviceTitle="{ rowData }") {{ rowData.serviceTitle }}
      template(#actions="{ rowData }")
        mini-loader.loader(v-if="loaderId === rowData.id")
        icon-button(
          v-else
          :size="16"
          :icon="UiIconNames.Icon_Next"
          tooltip-title="Редактировать акцию"
          @click="editPromo(rowData)"
        )

    ui-pagination(
      :count-results="data.length"
      :total-results="totalResults"
      :current-page="preFiltrationForm.page"
      :total="totalPages"
      @update:current-page="jumpToPage($event, false)"
    )

  .promo-editor(v-if="editablePromo")
    go-back.go-back(bold :icon="UiIconNames.Icon_Back" @click="goBack") Вернуться назад
    promo-editor(v-model="editablePromo" :is-create="isCreateMode")
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useApi } from "~/use/api/useApi";
import { formatDateTime } from "~/utils/formatter/dateFormatter";

import SettingsPanel from "~/components/pages/settings/common/SettingsPanel.vue";
import UiTable from "~/components/ui/table/UiTable.vue";
import UiPagination from "~/components/ui/pagination/UiPagination.vue";
import IconButton from "~/components/ui/button/IconButton.vue";
import PromoEditor from "~/components/pages/admin/promo/PromoEditor.vue";
import UiButton from "~/components/ui/button/UiButton.vue";
import MiniLoader from "~/components/ui/loader/MiniLoader.vue";
import GoBack from "~/components/other/GoBack.vue";

import { type PromoI, usePromo } from "~/use/other/usePromo";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";
import UiIconNames from "~/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "PromoList",
  components: {
    GoBack,
    MiniLoader,
    UiButton,
    PromoEditor,
    IconButton,
    UiTable,
    UiPagination,
    SettingsPanel,
  },
  methods: {
    formatDateTime,
  },
  setup() {

    const {
      getPromoPeriod,
      getDefaultPromoData,
      validateResponse,
    } = usePromo();

    const data = ref<PromoI[]>([]);
    const error = ref<boolean>(false);

    const isFetching = ref<boolean>(true);

    const totalPages = ref<number>(1);
    const totalResults = ref<number>(0);

    const editablePromo = ref<PromoI|undefined>();
    const isCreateMode = ref<boolean>(false);

    const loaderId = ref<number>(0);

    const preFiltrationForm = ref({
      page: 1,
      sorting: [{ title: 'dateCreate', order: 'desc' }]
    });

    const columns = computed(() => ({
      dateStart: { size: 156, headerLabel: 'Дата начала' },
      dateFinish: { size: 156, headerLabel: 'Дата окончания' },
      serviceTitle: { size: 718, bold: true, headerLabel: 'Название акции' },
      actions: { size: 44 },
    }))

    const pageTitle = computed(() => {
      if (editablePromo.value) return isCreateMode.value ? 'Создание акции' : 'Редактирование акции'
      return 'Список акций'
    })

    /** получение списка периодизаций акций */
    getPromoPeriod()

    /** получение списка всех акций */
    useApi().promo.fetchPromoList<PromoI[]>(preFiltrationForm.value)
      .then((hydraData: any) => {
        totalResults.value = Number(hydraData['totalItems'])
        totalPages.value = Math.ceil(hydraData['totalItems'] === 0 ? 1 : (hydraData['totalItems'] / 30))
        data.value = hydraData['member']
      })
      .catch(() => error.value = true)
      .finally(() => isFetching.value = false)

    function jumpToPage(p: number, resetPages = true) {
      preFiltrationForm.value.page = p
      applyFilters(resetPages)
    }

    /** фильтрация акций */
    function applyFilters(resetPages: boolean) {
      isFetching.value = true;

      if (resetPages) {
        totalResults.value = 0
        totalPages.value = 1
      }

      data.value = []
      error.value = false

      useApi().promo.fetchPromoList<PromoI[]>(preFiltrationForm.value)
        .then((filtersData) => {
          totalResults.value = Number(filtersData['totalItems'])
          totalPages.value = Math.ceil(filtersData['totalItems'] === 0 ? 1 : (filtersData['totalItems'] / 30))
          data.value = filtersData['member']
        })
        .catch(() => error.value = true)
        .finally(() => isFetching.value = false)
    }

    /** переход к редактированию акции */
    function editPromo(p: PromoI) {
      if (!p.id) return;

      isCreateMode.value = false;
      loaderId.value = p.id

      useApi().promo.fetchPromoById(p.id)
          .then((response) => editablePromo.value = validateResponse(response))
          .catch(() => useNotificationsStore().showError('Не удалось получить подробности акции'))
          .finally(() => loaderId.value = 0)
    }

    function createPromo() {
      isCreateMode.value = true;
      editablePromo.value = getDefaultPromoData();
    }

    /** выход из редактирования акции */
    function goBack() {
      editablePromo.value = undefined;
    }

    return {
      data,
      error,
      isFetching,
      totalPages,
      totalResults,
      preFiltrationForm,
      loaderId,
      editablePromo,
      isCreateMode,
      pageTitle,
      columns,
      UiIconNames,
      goBack,
      editPromo,
      createPromo,
      jumpToPage,
      applyFilters,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/links";

.link {
  @include link-mixin;

  &:hover {
    color: var(--main-color-blue-dark);
  }
}

.promo-table,
.promo-editor {
  display: flex;
  flex-flow: column;
  gap: 24px;
}

.go-back {
  cursor: pointer;
  color: var(--default-blue-color) !important;
}

.loader {
  align-self: center;
}
</style>
