<template lang="pug">
.flex-column.g16
  ui-loader(v-if="isFetching")
  .flex-column.g16(v-else-if="!showErrorWhileFetch")
    .flex-column(v-if="data.length || editableTag")
      .flex-row.tag(v-for="(tag, index) of editableTag?.id === NEW_ID ? [...data, editableTag] : data" :key="tag.id + index")
        .flex-column.g8.w100(v-if="tag.id === editableTag?.id")
          .flex-row
            el-color-picker(v-model="editableTag.color" color-format="hex" :predefine="predefineColors")
            form-input(
              v-model="editableTag.title"
              placeholder="Введите название тега"
              show-word-limit
              :max-length="32"
              @enter="updateTag(tag)"
            )
          span.error-message(v-if="showValidateError") Укажите цвет и название тега
        .flex-row.plain(v-else)
          .color(:style="`background-color: ${tag.color}`")
          .label {{ tag.title }}
        .actions(:class="tag.id === editableTag?.id && '_edit'" v-if="!roleReadOnly && !roleReadWriteLocal")
          mini-loader.loader(v-if="isSaving && editableTag?.id === tag.id")
          icon-button(
            v-else
            :size="16"
            :icon="editableTag?.id === tag.id ? UiIconNames.Icon_CheckUnderline : UiIconNames.Icon_Edit"
            :tooltip-title="editableTag?.id === tag.id ? 'Сохранить изменения' : 'Редактировать тег'"
            :disabled="!!editableTag && editableTag?.id !== tag.id"
            @click="updateTag(tag)"
          )
          icon-button(
            v-if="editableTag?.id === tag.id"
            :size="16"
            :icon="UiIconNames.Icon_CloseBold"
            :disabled="isSaving && editableTag?.id === tag.id"
            tooltip-title="Отменить изменения"
            @click="cancelChanges"
          )
          icon-button(
            type="danger"
            tooltip-title="Удалить тег"
            :size="16"
            :icon="UiIconNames.Icon_DeleteSmoothed"
            @click="onDeleteClick(tag)"
          )
    .empty-tags(v-else-if="!showErrorWhileFetch") У Вас нет созданных тегов
    ui-button.fit-content(v-if="!showErrorWhileFetch && !roleReadOnly && !roleReadWriteLocal" type="secondary" :disabled="!!editableTag" @click="addTag") Добавить тег
  span.error-message(v-if="showErrorWhileFetch") Произошла ошибка при получении списка тегов
  span.info-message(v-if="!roleReadOnly && !roleReadWriteLocal")
    | Теги используются для выделения закупок цветом для ускорения визуального поиска.
    |
    | Вы можете сами настраивать теги - давать им цвет и название, после чего назначать нужный тег на любой найденный тендер.

confirm-delete(v-model:show="showPopConfirm" :loader="isDeleting" @delete="deleteTag")
  template(#header) Удалить тег "{{ tagToDelete?.title }}"?
  template(#content) Удаленный тег будет снят со всех тендеров, на которые он был назначен
  template(#error v-if="showErrorWhileDelete") Не удалось удалить тег, попробуйте позже

pop-confirm(v-model:show="showUnsavedChanges" @event="popConfirmEvent($event, saveChangesBeforeLeave)" show-save-button)
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, ref } from 'vue'
import { onBeforeRouteLeave } from "vue-router";
import { useSuperAccount } from "~/use/superAccount/useSuperAccount";
import { useApi } from "~/use/api/useApi";
import { storeToRefs } from "pinia";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import UiButton from "~/components/ui/button/UiButton.vue";
import SettingsPanel from "~/components/pages/settings/common/SettingsPanel.vue";
import IconButton from "~/components/ui/button/IconButton.vue";
import MiniLoader from "~/components/ui/loader/MiniLoader.vue";
import FormInput from "~/components/ui/form/input/FormInput.vue";
import UiLoader from "~/components/ui/loader/UiLoader.vue";
import ConfirmDelete from "~/components/other/ConfirmDelete.vue";

import type { TenderTagI } from "~/stores/search/LotItemInterface";
import UiIconNames from "~/components/ui/icon/UiIconNames";
import cloneDeep from "lodash/cloneDeep";
import useSearchStore from "~/stores/search/useSearchStore";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";
import { Tabs } from "~/router/tabs";
import { usePopConfirm } from "~/use/confirm/usePopConfirm";

function getDefaultTag(list: string[]): TenderTagI {
  return {
    id: NEW_ID,
    color: list[Math.floor(Math.random() * list.length)] || '#409eff',
    title: '',
  }
}

const NEW_ID = -7;

const colors = [
  '#CC0000',  // safe
  '#FF6600',  // safe
  '#FFCC00',  // safe
  '#9ECF9D',
  '#00ced1',
  '#80ea92',
  '#6699FF',  // safe
  '#3333CC',  // safe
  '#7668b6',
  '#EE6CE7',
  '#99CCFF',  // safe
  '#409eff',  // safe
  '#b2c87e',
  '#627140',
  '#657934',
  '#d15c47',
  '#FF9966',  // safe
  '#993333',  // safe
  '#d15c47',
  '#999999',  // safe
]

export default defineComponent({
  name: "TagsManagement",
  components: {
    ConfirmDelete,
    UiLoader,
    FormInput,
    MiniLoader,
    IconButton,
    UiButton,
    SettingsPanel,
    PopConfirm: defineAsyncComponent(() => import("@/components/other/PopConfirm.vue")),
  },
  emits: [
    'tag:delete',
  ],
  setup(props, context) {

    const refLabel = ref();
    const editableTag = ref();
    const tagToDelete = ref();
    const showPopConfirm = ref(false);

    const isFetching = ref(true);          // loader при запросе списка тегов
    const isSaving = ref(false);           // loader при сохранении изменений тега
    const isDeleting = ref(false);         // loader при удалении тега

    const showErrorWhileFetch = ref(false);   // ошибка при запросе списка тегов
    const showErrorWhileSave = ref(false);    // ошибка при сохранении тега
    const showErrorWhileDelete = ref(false);  // ошибка при удалении тега
    const showValidateError = ref(false);     // ошибка валидации

    const predefineColors = ref(colors);

    const searchStore = useSearchStore();
    const { tags: data } = storeToRefs(searchStore);
    const { getTenderTags } = searchStore;

    const { showError } = useNotificationsStore();
    const { getDotQueryActual, assignResourceId } = useSuperAccount();
    const { roleReadOnly, roleReadWriteLocal } = useUserAccess();

    /** запрос актуальных тегов */
    getTenderTags(getDotQueryActual())
      .catch(() => showErrorWhileFetch.value = true)
      .finally(() => isFetching.value = false)

    function addTag() {
      editableTag.value = getDefaultTag(predefineColors.value.filter(e => !data.value.find(t => t.color === e)))
    }

    function onDeleteClick(tag: TenderTagI) {
      if (tag?.id === NEW_ID) {
        editableTag.value = null
        return
      }

      tagToDelete.value = tag
      showValidateError.value = false
      showErrorWhileDelete.value = false
      showPopConfirm.value = true
    }

    function deleteTag() {
      showErrorWhileDelete.value = false
      isDeleting.value = true

      useApi().tags.deleteTenderTag(tagToDelete.value?.id)
        .then(() => {
          const index = data.value.findIndex(e => e.id === tagToDelete.value?.id)
          if (index !== -1) data.value.splice(index, 1)
          showPopConfirm.value = false
          context.emit('tag:delete', tagToDelete.value)
        })
        .catch(() => showErrorWhileDelete.value = true)
        .finally(() => isDeleting.value = false)
    }

    function updateTag(tag: TenderTagI) {
      if (editableTag.value) request(editableTag.value)
      else editableTag.value = cloneDeep(tag)
    }

    function cancelChanges() {
      showValidateError.value = false
      editableTag.value = null
    }

    function request(payload: TenderTagI) {
      showValidateError.value = false

      if (!payload.color || !payload.title) {
        showValidateError.value = true
        return
      }

      isSaving.value = true;

      (payload.id === NEW_ID
        ? useApi().tags.postTenderTag<TenderTagI>(assignResourceId({ title: payload.title, color: payload.color }))
        : useApi().tags.patchTenderTag<TenderTagI>(payload.id, { title: payload.title, color: payload.color }))
          .then((response: TenderTagI) => {
            if (payload.id === NEW_ID) data.value.push(response)
            else {
              const index = data.value.findIndex(e => e.id === payload.id)
              if (index !== -1) data.value[index] = response
            }
            editableTag.value = null
          })
          .catch(() => showError(payload.id === NEW_ID ? 'Не удалось создать тег, попробуйте позже' : 'Не удалось сохранить изменения, попробуйте позже'))
          .finally(() => isSaving.value = false)
    }

    const {
      showPopConfirm: showUnsavedChanges,
      openPopConfirm,
      popConfirmEvent,
    } = usePopConfirm();

    onBeforeRouteLeave((to, from, next) => {
      if (!editableTag.value || to.name === Tabs.Landing) next()
      else openPopConfirm(true, next)
    })

    function saveChangesBeforeLeave() {
      if (editableTag.value) request(editableTag.value)
    }

    return {
      NEW_ID,
      editableTag,
      data,
      refLabel,
      isFetching,
      isSaving,
      isDeleting,
      showErrorWhileFetch,
      showErrorWhileDelete,
      showUnsavedChanges,
      showErrorWhileSave,
      showValidateError,
      predefineColors,
      roleReadOnly,
      roleReadWriteLocal,
      showPopConfirm,
      tagToDelete,
      addTag,
      updateTag,
      deleteTag,
      cancelChanges,
      onDeleteClick,
      popConfirmEvent,
      saveChangesBeforeLeave,
      UiIconNames,
    };
  }
})
</script>

<style scoped lang="scss">
@import 'assets/styles/mixin/fonts';

.flex-row {
  display: flex;
  flex-flow: row;
  gap: 8px;
}

.flex-column {
  display: flex;
  flex-flow: column;

  &.g0 {
    gap: 0;
  }

  &.g8 {
    gap: 8px;
  }

  &.g16 {
    gap: 16px;
  }
}

.info-message {
  @include label-13-18;
  color: var(--main-placeholder-color);
}

.error-message {
  @include label-13-18;
  color: var(--main-red-color);
}

.empty-tags {
  font-size: 14px;
  line-height: 20px;
}

.tag {
  padding: 8px 12px;
  align-items: start;

  :deep(.el-color-picker__color-inner) {
    border-radius: 1px;
  }

  &:hover {
    background-color: #fbfbfb;
  }
}

.plain {
  font-size: 14px;
}

.w100 {
  width: 100%;
}

.fit-content {
  width: fit-content;
  //margin-left: auto;
}

.color {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  margin: 0 6px;
}

.actions {
  display: flex;
  margin-left: auto;

  &._edit {
    margin-top: 8px;
  }
}

.loader {
  margin: 0 6px;
}
</style>
