<template lang="pug">
.settings-page
  .wrapper
    go-back.go-back(:is-go-back="isGoBack" :to="Tabs.Settings.Main") {{ isGoBack ? 'Вернуться назад' : 'Вернуться к настройкам' }}
  .settings-page__title
    h1 {{ title }}
    .header-actions(v-if="$slots.headerButtons")
      slot(name="headerButtons")
  .settings-page__container
    slot
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Tabs } from "@/router/tabs";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import GoBack from "~/components/other/GoBack.vue";

import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "SettingsPage",
  components: {
    GoBack,
    UiIcon,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    isGoBack: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    return {
      Tabs,
      UiIconNames,
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";

.settings-page {
  @include adaptive-view;
}

.wrapper {
  display: flex;
  width: fit-content;
}

.settings-page__title {
  display: flex;
  flex-flow: row wrap;
  padding: 16px 0 32px;
  gap: 8px;

  h1 {
    color: var(--main-color-black);
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-transform: uppercase;
    flex: 1 1 0;
  }

  .header-actions {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    margin-left: auto;
    height: fit-content;
    justify-content: end;
  }
}

.settings-page__container {
  display: flex;
  flex-flow: column;
  gap: 32px;
}

.go-back {
  margin-top: 24px;
}
</style>
